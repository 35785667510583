var __responsiveBreakpoints: ResponsiveBreakpoints;

/**
 * Initializes responsive breakpoints, MUST be called before calling the "responsive" function.
 * @param breakpoints Breakpoint label-to-maximum screen width map, e.g. {mobile: 540, tablet: 1024, desktop: Infinity}
 */
function setResponsive(breakpoints: map<string, number>) {
    __responsiveBreakpoints = getResponsive(breakpoints);
}

/**
 * Converts a breakpoint label-to-maximum screen width map into a ResponsiveBreakpoints object, suitable as the optional parameter od teh "responsive" function.
 * @param breakpoints Breakpoint label-to-maximum screen width map, e.g. {mobile: 540, tablet: 1024, desktop: Infinity}
 * @returns Converted ResponsiveBreakpoints object
 */
function getResponsive(breakpoints?: map<string, number>) {
    if (breakpoints) {
        return {
            widths: {...breakpoints},
            sortedKeys: Object.entries(breakpoints).sort((a, b) => a[1] - b[1]).map(pair => pair[0]),
        }
    }
    else {
        return __responsiveBreakpoints;
    }
}

/**
 * Calls the appropiate function in "behaviors", according to the current screen width. Returns null if "setResponsive" was not called before this function.
 * @param behaviors Breakpoint label-to-function map, e.g. {mobile: someFunction, desktop: someOtherFunction}
 * @param breakpoints Optional ResponsiveBreakpoints object, will use the one initialized with "setResponsive" by default
 * @returns Return value of the called function, if applicable
 */
function responsive<T>(behaviors: {[key: string]: () => T}, breakpoints=__responsiveBreakpoints): T | null {
    if (!breakpoints)
        return null;
    
    const screenWidth: number = window.innerWidth;
    const sortedBehaviors = (function() {
        let res : [string, () => T][] = [];
        for (const key of breakpoints.sortedKeys) {
            if (key in behaviors) {
                res.push([key, behaviors[key]]);
            }
        }
        return res;
    }());

    for (const [key, fn] of sortedBehaviors) {
        if (screenWidth <= breakpoints.widths[key]) {
            return fn();
        }
    }

    return null;
}

/**
 * Returns a string representing the current orientation of the window ('portrait' or 'landscape').
 * @returns String representing the orientation of the window
 */
function getOrientation() : 'landscape' | 'portrait' {
    if (window.outerHeight > window.outerWidth) {
        return 'portrait';
    }
    else {
        return 'landscape';
    }
}