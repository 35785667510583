function homePage() {
    $('#home-container').on('modal:was-closed', function(event, data) {
        if (data == '#subscription-expiring') {
            createCookie('subscription-expiration-warned', 'true', 1);
        }
    });

    $('#category-swipers, #tabbed-gender-swipers').on('tabs:was-changed', function(event, container, tab) {
        const swiperId = '#' + $(container).find(tab).find('.swiper-container').attr('id');
        if (swipers[swiperId]) {
            swipers[swiperId].update();
        }
    });
}