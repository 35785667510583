function passwordRecoveryPage() {

    $('#password-new-form-email').validate({
        errorPlacement: customErrorPlacement,
        submitHandler: function (form) {
            const $form = $(form);
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_TOKEN_SENT']);
                        const $tokenForm = $('#password-new-form-token');
                        $form.addClass('hide');
                        $tokenForm.removeClass('hide');
                        copyFormInputs('#password-new-form-email', '#password-new-form-token', {
                            'email': 'email'
                        });
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            })
        }
    });

    $('#password-new-form-token').validate({
        rules: {
            password: {
                complexPassword: true,
                required: true
            }
        },
        errorPlacement: customErrorPlacement,
        submitHandler: function (form) {
            const $form = $(form);
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_RESET'], function () {
                            window.location.href = $form.data('nexturl');
                        });
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            })
        }
    });
}