var BgLazyLoader;
/**
 * Initializes the BgLazyLoader IntersectionObserver, which will load the background images of <div> tags with the data-lazy-load attribute.
 * @param {JQuerySelector} scope Scope within which enable background image lazy load
 */
function initBackgroundImageLazyLoad(scope) {
    if (scope === void 0) { scope = document; }
    var lazyBgImages = $(scope).find('.background-image[data-lazy-load]').not(initialized);
    if (exists(lazyBgImages)) {
        if (BgLazyLoader === undefined) {
            BgLazyLoader = new IntersectionObserver(function (bgImages) {
                var _loop_1 = function (background) {
                    if (background.data('lazy-bg-initialized') === undefined) {
                        var src = background.data('src');
                        if (src.includes('::')) {
                            var queries_1 = {
                                widths: [],
                                heights: [],
                                sets: []
                            };
                            for (var _b = 0, _c = src.split(';'); _b < _c.length; _b++) {
                                var entry = _c[_b];
                                var _d = entry.split('::'), media = _d[0], set = _d[1];
                                var mediaValue = parseInt(media.split(':')[1].replace('px', ''));
                                var setObj = {
                                    width: { val: 0, dir: 'down' },
                                    height: { val: 0, dir: 'down' },
                                    val: set
                                };
                                if (media.includes('min-width')) {
                                    queries_1.widths.push({ val: mediaValue, dir: 'up' });
                                    setObj.width = { val: mediaValue, dir: 'up' };
                                }
                                else if (media.includes('max-width')) {
                                    queries_1.widths.push({ val: mediaValue, dir: 'down' });
                                    setObj.width = { val: mediaValue, dir: 'down' };
                                }
                                else if (media.includes('min-height')) {
                                    queries_1.heights.push({ val: mediaValue, dir: 'up' });
                                    setObj.height = { val: mediaValue, dir: 'up' };
                                }
                                else if (media.includes('max-height')) {
                                    queries_1.heights.push({ val: mediaValue, dir: 'down' });
                                    setObj.height = { val: mediaValue, dir: 'down' };
                                }
                                queries_1.sets.push(setObj);
                            }
                            function setImageSrc() {
                                if (queries_1.widths.length > 0 || queries_1.heights.length > 0) {
                                    var windowWidth = window.innerWidth;
                                    var windowHeight = window.innerHeight;
                                    var width_1, height_1;
                                    for (var _i = 0, _a = queries_1.widths; _i < _a.length; _i++) {
                                        var queryWidth = _a[_i];
                                        var queryCheck = (queryWidth.dir === 'up') ? windowWidth >= queryWidth.val : windowWidth <= queryWidth.val;
                                        if (!queryCheck) {
                                            break;
                                        }
                                        width_1 = queryWidth;
                                    }
                                    for (var _b = 0, _c = queries_1.heights; _b < _c.length; _b++) {
                                        var queryHeight = _c[_b];
                                        var queryCheck = (queryHeight.dir === 'up') ? queryHeight.val >= windowHeight : queryHeight.val <= windowHeight;
                                        if (!queryCheck) {
                                            break;
                                        }
                                        height_1 = queryHeight;
                                    }
                                    var set = queries_1.sets.filter(function (set) {
                                        return ((!width_1 || (width_1.val == set.width.val && width_1.dir == set.width.dir)) &&
                                            (!height_1 || (height_1.val == set.height.val && height_1.dir == set.height.dir)));
                                    });
                                    if (set.length > 0) {
                                        background.css('background-image', set[0].val);
                                    }
                                    console.log([width_1, height_1], set[0]);
                                }
                                if (background.css('background-image') == 'none') {
                                    background.css('background-image', "url(\"".concat(background.data('src-fallback'), "\")"));
                                }
                            }
                            $(window).on('resize orientationchange', setImageSrc);
                            setImageSrc();
                        }
                        else {
                            background.css('background-image', "url(".concat(background.data('src'), ")"));
                        }
                        background.find('.alt').css('display', 'none');
                        background.data('lazy-bg-initialized', true);
                    }
                };
                for (var _i = 0, _a = bgImages.map(function (bg) { return $(bg.target); }); _i < _a.length; _i++) {
                    var background = _a[_i];
                    _loop_1(background);
                }
            });
        }
        for (var _i = 0, _a = lazyBgImages.toArray(); _i < _a.length; _i++) {
            var bgImage = _a[_i];
            BgLazyLoader.observe(bgImage);
        }
    }
    function initialized() {
        return $(this).hasData('lazy-bg-initialized');
    }
}
