function checkoutPage() {
    'use strict';
    function submitAddressEditForm(form) {
        var href = form.data('nextstep');
        if (!form.data('request-open')) {
            form.data('request-open', true);
            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: form.serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success && data.data == 'VAT_CHANGED') {
                        href = '//' + location.host + location.pathname + '?warning=msg.order.vat.changed';
                    }
                    if (form.is('#dispatch-address-form') && window.dataLayer) {
                        dataLayer.push({ ecommerce: null });
                        pushGTMEventWithCallback(300, {
                            event: 'add_shipping_info',
                            ecommerce: window.ecommerce,
                            eventCallback: GTMCallback(function () {
                                window.location.href = href;
                            })
                        });
                    }
                    else {
                        window.location.href = href;
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function () {
                    form.data('request-open', false);
                }
            });
        }
    }
    $('#dispatch-address-form, #billing-address-form').validate({
        errorPlacement: customErrorPlacement,
        submitHandler: function (form) { return submitAddressEditForm($(form)); }
    });
    $('#copy-billing').on('click', function () {
        var url = $(this).data('url');
        var shippingForm = $(this).closest('form');
        var fields = shippingForm
            .find('input, select').not('[type=hidden]').toArray()
            .map(function (input) { return $(input).attr('name'); })
            .reduce(function (map, name) {
            map[name] = name;
            return map;
        }, {});
        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'html',
            beforeSend: function () {
                shippingForm.addClass('loading');
            },
            success: function (data) {
                var response = $('<div>').append(data);
                var billingForm = response.find('#checkout-container form');
                copyFormInputs(billingForm, shippingForm, fields);
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function () {
                shippingForm.removeClass('loading');
            }
        });
    });
    monitorInput('#billing-address-form', 'country_id', function (input) {
        var eu = $(input).find('option:selected').data('eu');
        return !!eu && eu != 'EXTRA-EU';
    }, function () {
        var input = $('#billing-address-form').find('[name=cfpiva]');
        input.required(true);
        $('#cfpiva-div').show();
    }, function () {
        var input = $('#billing-address-form').find('[name=cfpiva]');
        input.required(false);
        $('#cfpiva-div').hide();
    });
    monitorInput('#billing-address-form', 'country_id', function (input) {
        var eu = $(input).find('option:selected').data('eu');
        return !!eu && eu == 'IT';
    }, function () {
        var sdi = $('#billing-address-form').find('[name=sdi]');
        var pec = $('#billing-address-form').find('[name=pec]');
        pec.disabled(false);
        sdi.disabled(false);
        pec.closest('#pec-div').show();
        sdi.closest('#sdi-div').show();
    }, function () {
        var sdi = $('#billing-address-form').find('[name=sdi]');
        var pec = $('#billing-address-form').find('[name=pec]');
        pec.disabled(true);
        sdi.disabled(true);
        pec.closest('#pec-div').hide();
        sdi.closest('#sdi-div').hide();
    });
    $('[name*="country"]').not('[type=hidden]').on('change', updateProvinces);
    updateProvinces();
    $('#payment-paypal-form').validate({
        submitHandler: function (form) {
            var $form = $(form);
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        if (window.dataLayer) {
                            dataLayer.push({ ecommerce: null });
                            pushGTMEventWithCallback(300, {
                                event: 'add_payment_info',
                                ecommerce: window.ecommerce('PayPal'),
                                eventCallback: GTMCallback(function () {
                                    window.location.href = data.message;
                                })
                            });
                        }
                        else {
                            window.location.href = data.message;
                        }
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            });
        }
    });
    var addPaymentInfo = function (form, type) {
        dataLayer.push({ ecommerce: null });
        pushGTMEventWithCallback(300, {
            event: 'add_payment_info',
            ecommerce: window.ecommerce(type),
            eventCallback: GTMCallback(function () {
                if (form.requestSubmit) {
                    form.requestSubmit();
                }
                else {
                    form.submit();
                }
            })
        });
    };
    $('#booking-btn').on('click', function (event) {
        if (window.dataLayer) {
            var form = this.closest('form');
            event.preventDefault();
            event.stopPropagation();
            addPaymentInfo(form, 'Booking');
        }
    });
    $('#cod-btn').on('click', function (event) {
        if (window.dataLayer) {
            var form = this.closest('form');
            event.preventDefault();
            event.stopPropagation();
            addPaymentInfo(form, 'COD');
        }
    });
    $('#rfq-btn').on('click', function (event) {
        if (window.dataLayer) {
            var form = this.closest('form');
            event.preventDefault();
            event.stopPropagation();
            addPaymentInfo(form, 'Request for quote');
        }
    });
}
