var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __responsiveBreakpoints;
/**
 * Initializes responsive breakpoints, MUST be called before calling the "responsive" function.
 * @param breakpoints Breakpoint label-to-maximum screen width map, e.g. {mobile: 540, tablet: 1024, desktop: Infinity}
 */
function setResponsive(breakpoints) {
    __responsiveBreakpoints = getResponsive(breakpoints);
}
/**
 * Converts a breakpoint label-to-maximum screen width map into a ResponsiveBreakpoints object, suitable as the optional parameter od teh "responsive" function.
 * @param breakpoints Breakpoint label-to-maximum screen width map, e.g. {mobile: 540, tablet: 1024, desktop: Infinity}
 * @returns Converted ResponsiveBreakpoints object
 */
function getResponsive(breakpoints) {
    if (breakpoints) {
        return {
            widths: __assign({}, breakpoints),
            sortedKeys: Object.entries(breakpoints).sort(function (a, b) { return a[1] - b[1]; }).map(function (pair) { return pair[0]; }),
        };
    }
    else {
        return __responsiveBreakpoints;
    }
}
/**
 * Calls the appropiate function in "behaviors", according to the current screen width. Returns null if "setResponsive" was not called before this function.
 * @param behaviors Breakpoint label-to-function map, e.g. {mobile: someFunction, desktop: someOtherFunction}
 * @param breakpoints Optional ResponsiveBreakpoints object, will use the one initialized with "setResponsive" by default
 * @returns Return value of the called function, if applicable
 */
function responsive(behaviors, breakpoints) {
    if (breakpoints === void 0) { breakpoints = __responsiveBreakpoints; }
    if (!breakpoints)
        return null;
    var screenWidth = window.innerWidth;
    var sortedBehaviors = (function () {
        var res = [];
        for (var _i = 0, _a = breakpoints.sortedKeys; _i < _a.length; _i++) {
            var key = _a[_i];
            if (key in behaviors) {
                res.push([key, behaviors[key]]);
            }
        }
        return res;
    }());
    for (var _i = 0, sortedBehaviors_1 = sortedBehaviors; _i < sortedBehaviors_1.length; _i++) {
        var _a = sortedBehaviors_1[_i], key = _a[0], fn = _a[1];
        if (screenWidth <= breakpoints.widths[key]) {
            return fn();
        }
    }
    return null;
}
/**
 * Returns a string representing the current orientation of the window ('portrait' or 'landscape').
 * @returns String representing the orientation of the window
 */
function getOrientation() {
    if (window.outerHeight > window.outerWidth) {
        return 'portrait';
    }
    else {
        return 'landscape';
    }
}
