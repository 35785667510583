function cartPage() {
    loadQtyInputs();
    
    $('.cart-update').on('submit', function(event) {
        event.preventDefault();
        const $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function(data) {
                rewixUpdateCart($form);
                window.location.href = '/current/cart';
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('.btn-cart-update').on('click', function(event) {
        event.preventDefault();
        const $form = $(this).closest('form');
        $form.trigger('submit');
    });

    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        const $button = $(this);
        let data: {[param: string]: string | number | string[]} = {};
        data[$button.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $button.attr('data-action'),
            data: data,
            success: function() {
                const container = $button.closest('.cart-input');
                rewixUpdateCart(container, true);
                window.location.href = '/current/cart';
            },
            error: function() {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $(".burn-coupon-form").on('submit', function(event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").value<string>().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    $('.carrier-form').change(function() {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    $('.cart-remove-all-products').on('click', function(event) {
    
        if (confirm(msg['MSG_DELETEALLPRODUCTS'])) {
            $('.cart-update').each(function() {
                const $this = $(this);
                const inputs = $this.find('.quantity-input');
                let data: {[param: string]: number} = {};
    
                inputs.each(function () {
                    const name = $(this).attr('name');
                    data[name] = 0;
                });
    
                $.ajax({
                    type: 'POST',
                    url: '/restful/shopping/public/checkout/addtocart',
                    data: data,
                    success: function() {
                        // Rewix.trackClearCart();
                        rewixUpdateCart($this, true);
                        window.location.href = '/current/cart';
                    },
                    error: function() {
                        showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
                    }
                });
            });
        }
    });
}