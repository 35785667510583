var stickyDetectors = [];
/**
 * Creates an IntersectionObserver object that adds the 'stuck' class to "elem" when "sentinel" exits the observer viewport, generally when "elem" reaches the viewport top.
 * Will not create an oberver if the element does not have position: sticky.
 * If "sentinel" is omitted, a new 0-height sentinel element will be created and inserted before "elem".
 * @param elem Element to which to add the 'stuck' class
 * @param sentinel Sentinel element to observe, will create a 0-height sentinel if omitted or non-existing
 * @param observerOptions IntersectionObserver options to pass to the observer
 */
function monitorStickyElement(elem, sentinel, observerOptions) {
    var $elem = $(elem);
    var $sentinel = $(sentinel);
    if (exists($elem) && $elem.css('position') === 'sticky') {
        var observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting) {
                $elem.removeClass('stuck');
            }
            else {
                $elem.addClass('stuck');
            }
        }, observerOptions);
        if (!exists($sentinel)) {
            var sentinelId = "__sticky-sentinel-".concat(stickyDetectors.length);
            $("<div id=\"".concat(sentinelId, "\"></div>")).insertBefore($elem);
            $sentinel = $("#".concat(sentinelId));
        }
        observer.observe($sentinel.get(0));
        stickyDetectors.push(observer);
    }
}
