/**
 * Initializes password eyes (show/hide password) in the provided scope.
 * @param scope Selector of the element within which activate password eyes
 */
function initPasswordEyes(scope: JQuerySelector = document) {
    $(scope).find('.password-input .password-eye').not(initialized).each(function() {
        const eye = $(this);
        const input = eye.closest('.password-input').find('input');
        const eyeOpen = eye.find('.eye-open');
        const eyeClosed = eye.find('.eye-closed');

        eye.data('password-eye-initialized', true);

        eyeOpen.on('click', function() {
            eyeOpen.addClass('hide');
            eyeClosed.removeClass('hide');
            input.attr('type', 'password');
        });

        eyeClosed.on('click', function() {
            eyeClosed.addClass('hide');
            eyeOpen.removeClass('hide');
            input.attr('type', 'text');
        });
    });

    function initialized() {
        return $(this).hasData('password-eye-initialized');
    }
}