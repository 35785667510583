/**
 * Initializes sortable tables within "scope". Use guide:
 * 
 * Terminology:
 *      table: the sortable <table> element
 *      header: <th> element heading the column by which to sort. By default the sorting will switch from default to ascending order to descending order to default.
 *      column: column corresponding to the header
 *      rows: row within the <tbody> of the table
 * 
 * Table attributes:
 *      data-table-sortable: Indicates that this element will be a sortable table
 *
 * Header attributes:
 *      data-table-sort="method": Indicates that this element will be a table header, "method" is the (optional) name of the sorting function to use
 *      data-table-sort-no-asc: Indicates that this column will not be sorted in ascending order
 *      data-table-sort-no-desc: Indicates that this column will not be sorted in descending order
 * 
 * Header classes:
 *      .sorting-key: added to all headers
 *      .sort-asc: currently sorting in ascending order
 *      .sort-desc: currently sorting in descending order
 * 
 * Table events:
 *      "table:sort"([index, direction, method?]): sorts the table by "index"-th column in the provided "direction", optionally using sorting function "method"
 * 
 * @param scope Selector of the element within which activate tabs
 */
function initSortableTables(scope: JQuerySelector = document) {
    $(scope).find('table').withData('table-sortable').not(initialized).each(function() {
        const table = $(this);

        table.data('sortable-table-initialized', true);

        table.find('tbody tr').toArray()
            .forEach((row, index) => $(row).data('table-sort-original-index', index));

        const headers = table.find('thead th').toArray();
        headers.forEach((th, index) => {
            const header = $(th);

            if (header.data('table-sort') !== undefined) {
                //--- Adjust index for colspan of current and previous headers
                const colspan = headers.slice(0, index + 1)
                    .map(th => parseInt($(th).attr('colspan')) || 1)
                    .reduce((a, b) => a + b - 1, 0);
                const trueIndex = index + colspan;
                //---
                
                header.addClass('sorting-key').data('table-sort-column-index', trueIndex);
    
                const sortMethod = header.data('table-sort');
                const noAsc = header.data('table-sort-no-asc') !== undefined;
                const noDesc = header.data('table-sort-no-desc') !== undefined;
    
                header.on('click', function() {
                    const currentState: SortDirection = (() => {
                        if (header.is('.sort-asc')) {
                            return 'asc';
                        }
                        else if (header.is('.sort-desc')) {
                            return 'desc';
                        }
                        else {
                            return 'unsorted';
                        }
                    })();
        
                    const nextState: SortDirection = (() => {
                        switch (currentState) {
                            case 'desc':
                                return 'unsorted';
        
                            case 'asc':
                                return noDesc ? 'unsorted' : 'desc';
        
                            case 'unsorted':
                                return noAsc ? (noDesc ? 'unsorted' : 'desc') : 'asc';
                        }
                    })();
    
                    if (nextState == 'desc') {
                        header.removeClass('sort-asc').addClass('sort-desc');
                    }
                    else if (nextState == 'asc') {
                        header.removeClass('sort-desc').addClass('sort-asc');
                    }
                    else {
                        header.removeClass(['sort-asc', 'sort-desc']);
                    }
                    table.find('thead th').not(header).removeClass(['sort-asc', 'sort-desc']);
    
                    header.trigger('table:sort', [trueIndex, nextState, sortMethod]);
                });
            }
        });

        table.on('table:sort', function(event, index: number, direction: SortDirection, sortMethod: string) {
            event.stopPropagation();

            const rows: TableRowsMap = table.find('tbody tr').toArray()
                .reduce((map: TableRowsMap, row: HTMLElement) => {
                    let key: string;

                    if (direction != 'unsorted') {
                        key = $(row).find('td').get(index).textContent.trim().replace(/\s+/gm, ' ');
                    }
                    else {
                        key = $(row).data('table-sort-original-index');
                    }

                    map[key] = (map[key] || []).concat(row);
                    return map;
                }, {});

            let keys = Object.keys(rows);

            if (sortMethod in window) {
                keys.sort((window as any)[sortMethod] as SortFunction);
            }
            else {
                keys.sort();
            }

            if (direction == 'desc') {
                keys.reverse();
            }

            const tbody = table.find('tbody');
            keys.forEach(key => {
                tbody.append(rows[key]);
            });
        });
    });

    function initialized() {
        return $(this).hasData('sortable-table-initialized');
    }
}

// Common sorting functions
function sortPrice(a: string, b: string): number {
    const notNumeric = /[^0-9\.,-]/g;
    const numA = parseFloat(a.replace(notNumeric, '').replace(/,/g, '.'));
    const numB = parseFloat(b.replace(notNumeric, '').replace(/,/g, '.'));

    return numA > numB ? 1 : -1;
}

const sortAlphanumeric = new Intl.Collator('en', { numeric: true }).compare;