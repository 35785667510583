/**
 * Extracts query parameters from the provided "url".
 * @param {string} url Url from which to obtain query parameters; current url by default
 * @returns {QueryParams} Query parameters object
 */
function getQueryParams(url: string = window.location.href): QueryParams {
    let queryParams: QueryParams = {};
    let [_, params] = url.split('?');

    if (params) {
        for (let param of params.split('&')) {
            let [key, value] = param.split('=');
            if (queryParams[key] && value) {
                if (Array.isArray(queryParams[key])) {
                    queryParams[key].concat(value);
                }
                else {
                    queryParams[key] = [queryParams[key] as string, value];
                }
            }
            else {
                queryParams[key] = value || '';
            }
        }
    }

    return queryParams;
}

/**
 * Extracts a single query parameter value from the current url.
 * @param {string} param Name of the parameter to get
 * @returns {string | string[] | ''} The value of the query parameter, or an empty string if the query parameter is not found
 */
function getQueryParam(param: string): string | string[] | '' {
  return getQueryParams()[param] || '';
}

/**
 * Sets a query parameter in the current url; if the parameter already exists it is overwritten.
 * @param {string} param Name of the query parameter to set
 * @param {string | string[]} value Value(s) of the parameter to set
 */
function setQueryParam(param: string, value: string | string[]) {
    let params = getQueryParams();
    params[param] = value;
    setQueryParams(params);
}

/**
 * Sets several query parameters in the current url; if the parameters already exists they will be overwritten.
 * @param {QueryParams} params Query parameters to set, defaults to an empty object
 */
function setQueryParams(params: QueryParams = {}) {
    if (Object.keys(params).length > 0) {
        window.location.search = queryParamsToString(params);
    }
    else {
        window.location.search = '';
    }
}


/**
 * Converts a QueryParams object to its string representation, ready to be assigned to window.location.search
 * @param {QueryParams} params Query parameters to convert, will use the query parameters of the current url by default
 * @returns String representation of the query parameters
 */
function queryParamsToString(params: QueryParams = getQueryParams()): string {
    let query = [];
    for (let param of Object.keys(params)) {
        if (params[param]) {
            if (Array.isArray(params[param])) {
                for (let value of params[param]) {
                    query.push(`${param}=${value}`);
                }
            }
            else {
                query.push(`${param}=${params[param] as string}`);
            }
        }
    }

    return `?${query.join('&')}`;
}

/**
 * Builds an url
 * @param {string} params.base Base of the url, defaults to window.location.origin + window.location.pathname if not provided
 * @param {QueryParams} params.queryParams Query parameters of the url, will use the ones of the current page by default
 * @param {string} params.hash Hash of the url, will use the one of the current page by default
 * @returns 
 */
function buildUrl(params: {base?: string, queryParams?: QueryParams, hash?: string} = {}): string {
    const base = params.base || (window.location.origin + window.location.pathname);
    const queryParams = queryParamsToString(params.queryParams || getQueryParams());
    const hash = params.hash || window.location.hash;

    return base + queryParams + hash;
}