var stickyDetectors: IntersectionObserver[] = [];

/**
 * Creates an IntersectionObserver object that adds the 'stuck' class to "elem" when "sentinel" exits the observer viewport, generally when "elem" reaches the viewport top.
 * Will not create an oberver if the element does not have position: sticky.
 * If "sentinel" is omitted, a new 0-height sentinel element will be created and inserted before "elem".
 * @param elem Element to which to add the 'stuck' class
 * @param sentinel Sentinel element to observe, will create a 0-height sentinel if omitted or non-existing
 * @param observerOptions IntersectionObserver options to pass to the observer
 */
function monitorStickyElement(elem: JQuerySelector, sentinel?: JQuerySelector, observerOptions?: IntersectionObserverInit) {
    const $elem = $(elem);
    let $sentinel = $(sentinel);

    if (exists($elem) && $elem.css('position') === 'sticky') {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                $elem.removeClass('stuck');
            }
            else {
                $elem.addClass('stuck');
            }
        }, observerOptions);

        if (!exists($sentinel)) {
            const sentinelId = `__sticky-sentinel-${stickyDetectors.length}`;
            $(`<div id="${sentinelId}"></div>`).insertBefore($elem);
            $sentinel = $(`#${sentinelId}`);
        }
    
        observer.observe($sentinel.get(0));
        stickyDetectors.push(observer);
    } 
}