var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var GridTable = /** @class */ (function () {
    function GridTable($el, el, headers, rows, cols, cells) {
        var _this = this;
        this.$el = $el;
        this.el = el;
        this.headers = headers;
        this.rows = rows;
        this.cols = cols;
        this.cells = cells;
        this.colMap = {};
        this.getHeaderIndex = function (key) {
            if (typeof key === 'number')
                return key;
            if (_this.headerNames.includes(key)) {
                return _this.headerNames.indexOf(key);
            }
            else {
                return false;
            }
        };
        this.headerNames = headers.map(function (item) { return item.data('name'); });
        for (var _i = 0, _a = indexValuePairs(this.headerNames); _i < _a.length; _i++) {
            var _b = _a[_i], idx = _b[0], name_1 = _b[1];
            this.colMap[name_1] = cols[idx];
        }
    }
    ;
    GridTable.prototype.column = function (key) {
        if (typeof key === "string") {
            if (key in this.colMap) {
                return this.colMap[key];
            }
            else {
                return [];
            }
        }
        else {
            return this.cols[key];
        }
    };
    GridTable.prototype.hideColumn = function (key) {
        if (Array.isArray(key)) {
            for (var _i = 0, key_1 = key; _i < key_1.length; _i++) {
                var k = key_1[_i];
                this.hideColumn(k);
            }
            return this;
        }
        var idx = this.getHeaderIndex(key);
        if (typeof idx === 'number') {
            if (this.headers[idx].is('.hide')) {
                return this;
            }
            else {
                this.headers[idx].addClass('hide');
            }
        }
        var column = this.column(key);
        for (var _a = 0, column_1 = column; _a < column_1.length; _a++) {
            var cell = column_1[_a];
            if (!cell.hasData('colspan') || cell.hasData('colspan', '1')) {
                cell.addClass('hide');
            }
        }
        this.$el.css('--cols', this.$el.children('.header:not(.hide)').length);
        column.filter(function (item) { return item.withData('colspan'); }).forEach(function (cell) {
            var colspan = parseInt(cell.data('colspan')) || 1;
            cell.data('colspan', colspan - 1).css('--colspan', colspan - 1);
        });
        return this;
    };
    GridTable.prototype.showColumn = function (key) {
        if (Array.isArray(key)) {
            for (var _i = 0, key_2 = key; _i < key_2.length; _i++) {
                var k = key_2[_i];
                this.showColumn(k);
            }
            return this;
        }
        var idx = this.getHeaderIndex(key);
        if (typeof idx === 'number') {
            if (this.headers[idx].is('.hide')) {
                this.headers[idx].removeClass('hide');
            }
            else {
                return this;
            }
        }
        var column = this.column(key);
        for (var _a = 0, column_2 = column; _a < column_2.length; _a++) {
            var cell = column_2[_a];
            cell.removeClass('hide');
        }
        this.$el.css('--cols', this.$el.children('.header:not(.hide)').length);
        column.filter(function (item) { return item.withData('colspan'); }).forEach(function (cell) {
            var colspan = parseInt(cell.data('colspan')) || 0;
            cell.data('colspan', colspan + 1).css('--colspan', colspan + 1);
        });
        return this;
    };
    GridTable.prototype.sortBy = function (key, method, dir) {
        if (method === void 0) { method = undefined; }
        if (dir === void 0) { dir = undefined; }
        var idx = this.getHeaderIndex(key);
        if (idx) {
            var header_1 = this.headers[idx];
            var sortMethodKey = method || header_1.data('grid-table-sort');
            var sortMethod_1 = gridTablesSortMethods[sortMethodKey];
            if (sortMethod_1) {
                var sortDir_1 = dir || (function () {
                    switch (header_1.data('grid-table-sort-dir')) {
                        case 'asc':
                            return 'desc';
                        case 'desc':
                            return 'none';
                        default:
                            return 'asc';
                    }
                })();
                var sortedRows = this.rows.concat([]);
                if (sortDir_1 != 'none') {
                    sortedRows.sort(function (a, b) {
                        if (sortDir_1 == 'asc') {
                            return sortMethod_1(a[idx], b[idx]);
                        }
                        else {
                            return -sortMethod_1(a[idx], b[idx]);
                        }
                    });
                }
                for (var _i = 0, sortedRows_1 = sortedRows; _i < sortedRows_1.length; _i++) {
                    var row = sortedRows_1[_i];
                    for (var _a = 0, row_1 = row; _a < row_1.length; _a++) {
                        var cell = row_1[_a];
                        this.$el.append(cell);
                    }
                }
                header_1.removeClass(['sorted-asc', 'sorted-desc']);
                if (sortDir_1 != 'none') {
                    header_1.addClass("sorted-".concat(sortDir_1));
                }
                header_1.data('grid-table-sort-dir', sortDir_1);
                this.headers
                    .filter(function (_, index) { return index != idx; })
                    .forEach(function (item) {
                    item
                        .removeClass(['sorted-asc', 'sorted-desc'])
                        .data('grid-table-sort-dir', 'none');
                });
            }
        }
        return this;
    };
    GridTable.prototype.where = function (condition) {
        return this.cells.filter(condition);
    };
    GridTable.prototype.slice = function (startPoint, endPoint) {
        var getTrueIndex = function (idx, max) { return idx == null ? max : (idx >= 0 ? idx : max + idx); };
        var col_max = this.cols.length - 1;
        var row_max = this.rows.length - 1;
        // [...[stuff]].map is a workaround to map empty array elements also
        var _a = __spreadArray([], (startPoint || [0, 0]), true).map(function (coord, i) { return getTrueIndex(coord, [col_max, row_max][i]); }), col_start = _a[0], row_start = _a[1];
        var _b = __spreadArray([], (endPoint || [col_max, row_max]), true).map(function (coord, i) { return getTrueIndex(coord, [col_max, row_max][i]); }), col_end = _b[0], row_end = _b[1];
        return this.where(function (cell) {
            var row_idx = cell.data('row-idx');
            var col_idx = cell.data('col-idx');
            return (row_idx >= row_start) && (row_idx <= row_end) && (col_idx >= col_start) && (col_idx <= col_end);
        });
    };
    return GridTable;
}());
var gridTables = {};
var gridTablesSortMethods = {
    text: function (cell1, cell2) {
        var text1 = cell1.text().trim();
        var text2 = cell2.text().trim();
        return text1.localeCompare(text2);
    },
    price: function (cell1, cell2) {
        var price1 = parseFloat(cell1.find('.price-container .price').text()) || 0;
        var price2 = parseFloat(cell2.find('.price-container .price').text()) || 0;
        return price1 - price2;
    }
};
function initGridTables(scope) {
    if (scope === void 0) { scope = document; }
    $(scope).find('.grid-table').not(initialized).each(function (table_idx) {
        var table = $(this);
        var headers = table.children('.header').toArray().map(function (item) { return $(item); });
        var col_num = headers.length;
        var cells = table.children('.cell').not('.header').toArray().map(function (cell) { return $(cell); });
        var rows = [];
        var cols = __spreadArray([], Array(col_num), true).map(function (_) { return []; });
        var row_idx = 0;
        if (table.attr('id') === undefined) {
            table.attr('id', "grid-table-".concat(table_idx));
        }
        for (var n = 0; n < cells.length;) {
            var row = getRow(row_idx);
            for (var col_idx = 0; col_idx < col_num;) {
                if (row[col_idx] !== undefined) {
                    cols[col_idx][row_idx] = row[col_idx];
                    col_idx += 1;
                    continue;
                }
                var colspan = Math.max(1, parseInt(cells[n].data('colspan')) >> 0);
                var rowspan = Math.max(1, parseInt(cells[n].data('rowspan')) >> 0);
                for (var x = col_idx; x < (col_idx + colspan); x++) {
                    row[x] = cells[n];
                    cols[x][row_idx] = cells[n];
                }
                for (var y = 1; y < rowspan; y++) {
                    getRow(row_idx + y)[col_idx] = cells[n];
                }
                cells[n].data('row-idx', row_idx);
                cells[n].data('col-idx', col_idx);
                col_idx += colspan;
                n += 1;
            }
            row_idx += 1;
        }
        headers.filter(function (item) { return item.hasData('grid-table-sort'); }).forEach(function (item) {
            if (item.data('grid-table-sort') in gridTablesSortMethods) {
                item.on('click', sortColumn);
            }
        });
        gridTables['#' + table.attr('id')] = new GridTable(table, table[0], headers, rows, cols, cells);
        table.data('grid-table-initialized', true);
        function getRow(idx) {
            if (rows[idx] === undefined) {
                rows[idx] = Array(col_num);
            }
            return rows[idx];
        }
    });
    function initialized() {
        return $(this).hasData('grid-table-initialized');
    }
    function sortColumn() {
        var header = $(this);
        var headerName = header.data('name');
        var tableId = header.closest('.grid-table').attr('id');
        var table = gridTables['#' + tableId];
        table.sortBy(headerName);
    }
}
