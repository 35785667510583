function subscriptionPage() {
    bindFormSubmitEvent();
    $(window).on('popstate', function () {
        var value = getQueryParam('option');
        debounce('subscribe-page:popstate', function () {
            $('#subscription-select').val(value);
            reloadSubscription(value);
        }, 500);
    });
    $('#subscription-select').on('change', function () {
        var value = $(this).value();
        var url = buildUrl({ queryParams: { option: value } });
        history.pushState(null, null, url);
        reloadSubscription(value);
    });
    function reloadSubscription(value) {
        var url = buildUrl({ queryParams: { option: value } });
        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'html',
            cache: true,
            success: function (data) {
                var response = $('<div>').append(data);
                var wrapper = response.find('#subscription-wrapper');
                $('#subscription-wrapper').replaceWith(wrapper);
                bindFormSubmitEvent();
            },
            error: function () {
                setQueryParam('option', value);
            }
        });
    }
    function bindFormSubmitEvent() {
        $('#monthly-subscription-form').validate({
            submitHandler: function (form) {
                var $form = $(form);
                $.ajax({
                    method: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        $form.find(':input').prop('disabled', true);
                    },
                    success: function (data) {
                        if (data.success) {
                            initializePayment(data.data, $form.data('nexturl'), $form);
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    },
                    error: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                    complete: function () {
                        $form.find(':input').prop('disabled', false);
                    }
                });
            }
        });
        $('#yearly-subscription-form').validate({
            submitHandler: function (form) {
                var $form = $(form);
                $.ajax({
                    method: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        $form.find(':input').prop('disabled', true);
                    },
                    success: function (data) {
                        if (data.success) {
                            initializePayment(data.data, $form.data('nexturl'), $form);
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    },
                    error: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                    complete: function () {
                        $form.find(':input').prop('disabled', false);
                    }
                });
            }
        });
    }
}
function initializePayment(orderId, nextUrl, form) {
    $.ajax({
        type: "POST",
        url: '/restful/shopping/checkout/payment/initialize',
        data: { orderId: orderId },
        dataType: 'json',
        success: function (data) {
            if (data.success) {
                var selectedOption = form;
                if (selectedOption) {
                    var matomoDataSignup = selectedOption.data('matomo-signup');
                    var matomoDataRecurring = selectedOption.data('matomo-recurring');
                    var dummyElement = $("\n                        <div>" +
                        (matomoDataSignup ? "\n                            <input type=\"hidden\" value=\"1\" data-matomo='".concat(JSON.stringify(matomoDataSignup), "'>\n                        ") : '') + "\n                            <input type=\"hidden\" value=\"1\" data-matomo='".concat(JSON.stringify(matomoDataRecurring), "'>\n                        </div>\n                    "));
                    rewixUpdateCart(dummyElement);
                }
                window.location.href = nextUrl;
            }
            else {
                alert('Cannot pay this order.');
            }
        },
        error: function (data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        }
    });
}
