

/**
 * Starts a countdown from the current time to "end", updated every "tickDuration" ms. The current countdown value is written into the "days", "hours", "minutes", "seconds", and "milliseconds" elements.
 * @param {Date} config.end Countdown final time
 * @param {number} config.tickDuration (Optional) Countdown update interval (in ms), defaults to 1000
 * @param {JQuerySelector} config.days (Optional) Element into which to write the remaining days
 * @param {JQuerySelector} config.hours (Optional) Element into which to write the remaining hours
 * @param {JQuerySelector} config.minutes (Optional) Element into which to write the remaining minutes
 * @param {JQuerySelector} config.seconds (Optional) Element into which to write the remaining seconds
 * @param {JQuerySelector} config.milliseconds (Optional) Element into which to write the remaining milliseconds
 * @param config.events (Optional) Data required to trigger events
 * @param {JQuerySelector} config.events.target (Optional) Target from which non-"every" events will be emitted, defaults to document
 * @param {boolean} config.events.onTick (Optional) Trigger 'countdown:tick' event on every countdown tick
 * @param {boolean} config.events.onEnd (Optional) Trigger 'countdown:end' event when the countdown is finished
 * @param config.events.every (Optional) Trigger 'countdown:${quantity}:${time}' event when "quantity" (days, hours, minutes, seconds, milliseconds) reaches one of the specified "time" values, e.g. every: {seconds: [0, 30]} will trigger 'countdown:seconds:0' and 'countdown:seconds:30' whenever the value of "seconds" reaches 0 or 30 respectively
 */
function setCountdown(config: CountdownConfig) {
    const days = config.days ? $(config.days) : null;
    const hours = config.hours ? $(config.hours) : null;
    const minutes = config.minutes ? $(config.minutes) : null;
    const seconds = config.seconds ? $(config.seconds) : null;
    const milliseconds = config.milliseconds ? $(config.milliseconds) : null;

    const daysDiff = (a: Date, b: Date) => Math.trunc((a.getTime() - b.getTime()) / (24 * 60 * 60 * 1000));

    const hoursDiff = (a: Date, b: Date) =>
        Math.trunc((a.getTime() - b.getTime()) / (60 * 60 * 1000)) %
        (days ? 24 : Infinity);

    const minutesDiff = (a: Date, b: Date) =>
        Math.trunc((a.getTime() - b.getTime()) / (60 * 1000)) %
        (hours ? 60 : Infinity);
        
    const secondsDiff = (a: Date, b: Date) =>
        Math.trunc((a.getTime() - b.getTime()) / 1000) %
        (minutes ? 60 : Infinity);
    
    const millisecondsDiff = (a: Date, b: Date) =>
        Math.trunc(a.getTime() - b.getTime()) %
        (seconds ? 1000 : Infinity);

    if (config.end.getTime() > Date.now()) {
        const fields: {$el: JQuery, diff_fn: (a: Date, b: Date) => number, trigger_id: string}[] = [
            {$el: days, diff_fn: daysDiff, trigger_id: 'days'},
            {$el: hours, diff_fn: hoursDiff, trigger_id: 'hours'},
            {$el: minutes, diff_fn: minutesDiff, trigger_id: 'minutes'},
            {$el: seconds, diff_fn: secondsDiff, trigger_id: 'seconds'},
            {$el: milliseconds, diff_fn: millisecondsDiff, trigger_id: 'milliseconds'}
        ].filter(item => !!item.$el);
    
        const interval = setInterval(() => {
            const now = new Date(Date.now());
            const end = config.end;
    
            if (end.getTime() - now.getTime() > 0) {
                config.events &&
                config.events.onTick &&
                $(config.events.target || document).trigger('countdown:tick');

                fields.forEach(field => {
                    const diff = field.diff_fn(end, now);
                    field.$el.text(diff);

                    config.events &&
                    field.trigger_id in config.events.every &&
                    config.events.every[field.trigger_id as keyof typeof config.events.every].includes(diff) &&
                    field.$el.trigger(`countdown:${field.trigger_id}:${diff}`)
                })
            }
            else {
                clearInterval(interval);
                if (fields.length > 0) {
                    fields.forEach(field => field.$el.text(0));
                    fields[0].$el.trigger('countdown:done')
                }

                config.events &&
                config.events.onEnd &&
                $(config.events.target || document).trigger('countdown:end');
            }
        }, config.tickDuration || 1000);
    }
}