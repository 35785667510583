/**
 * Initializes modal elements within "scope". Use guide:
 * 
 * Terminology:
 *      modal: modal element container
 *      content: window containing the elements within the modal
 *      overlay: fixed display overlay surrounding the modal content
 * 
 * Modal attributes:
 *      data-modal: Indicates that this element will be a modal element
 * 
 * Overlay attributes:
 *      data-modal-overlay: Indicates that clicking on this overlay element will close the modal, if it is absent the modall will have to be closed in some other way
 * 
 * Content attributes:
 *      data-modal-close: elements within the content with this attribute will close the modal
 * 
 * Modal classes:
 *      .open: when the modal is open
 *      
 * 
 * Modal events:
 *      "modal:open": opens the modal element
 *      "modal:close": closes the modal element
 *      "modal:was-opened"(selector): emitted by the modal element when it is opened, "selector" is the unique selector of the modal
 *      "modal:was-closed"(selector): emitted by the modal element when it is closed, "selector" is the unique selector of the modal
 * 
 * @param scope Selector of the element within which activate modal elements
 */
function initModals(scope: JQuerySelector = document) {
    $(scope).find('*').withData('modal').not(initialized).each(function() {
        const modal = $(this);
        const overlay = modal.find('.overlay');
        const content = modal.find('.content');
        const identifier = modal.attr('id') ? `#${modal.attr('id')}` : `.modal:nth(${modal.index('.modal')})`;
        
        // Integration with toggle
        if (modal.hasEventHandler('toggle:open') && modal.hasEventHandler('toggle:close')) {
            modal
                .on('modal:open', function() {
                    modal.trigger('toggle:open');
                })
                .on('modal:close', function() {
                    modal.trigger('toggle:close');
                })
                .on('toggle:open', function() {
                    blockPageScroll();
                });
        }

        modal
            .on('modal:open', function(event) {
                event.stopPropagation();
                blockPageScroll();
                modal
                    .addClass('open')
                    .trigger('modal:was-opened', identifier);
            })
            .on('modal:close', function(event) {
                event.stopPropagation();
                restorePageScroll();
                modal
                    .removeClass('open')
                    .trigger('modal:was-closed', identifier);
            });

        overlay.withData('modal-overlay').on('click', function() {
            modal.trigger('modal:close');
        });

        content.find('*').withData('modal-close').on('click', function() {
            modal.trigger('modal:close');
        });

        modal.data('modal-initialized', true);

        if (modal.is('.open')) {
            modal.trigger('modal:open');
        }
        else {
            modal.trigger('modal:close');
        }
    });

    function initialized() {
        return $(this).hasData('modal-initialized');
    }
}