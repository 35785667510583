/**
 * Disables scrolling of the \<body\> tag.
 */
function blockPageScroll() {
    $('body').addClass('block-scroll');
}

/**
 * Re-enables scrolling of the \<body\> tag.
 */
function restorePageScroll() {
    $('body').removeClass('block-scroll');
}

/**
 * Checks if the .block-scroll class is set.
 * @returns True if the page is scrollable
 */
function pageIsScrollable() {
    return !$('body').is('.block-scroll');
}