var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
;
var swipers = {};
var defaultSwiperSettings = {
    slidesPerView: 1,
    centerInsufficientSlides: true,
    spaceBetween: 20,
    autoplay: {
        delay: 2000,
    },
    speed: 1500,
    breakpoints: {
        641: {
            slidesPerView: 2
        },
        1025: {
            slidesPerView: 4
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};
var noSwiperNavigation = {
    navigation: {},
};
var swiperLoop = {
    loop: true,
};
var addSwiperEntry;
function swiperComponent() {
    requireModule('swiper', function () {
        addSwiperEntry = function (key, settings, eventHandlers) {
            if (eventHandlers === void 0) { eventHandlers = {}; }
            if (exists(key) && not(swipers[key])) {
                swipers[key] = new Swiper(key, settings);
                for (var _i = 0, _a = Object.entries(eventHandlers); _i < _a.length; _i++) {
                    var _b = _a[_i], event_1 = _b[0], handler = _b[1];
                    swipers[key].on(event_1, handler);
                }
            }
        };
        // BEGIN: Custom swipers
        addSwiperEntry('#brands-swiper', __assign(__assign(__assign({}, defaultSwiperSettings), noSwiperNavigation), {
            slidesPerView: 2.5,
            spaceBetween: 8,
            loop: true,
            speed: 2500,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 3.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 5
                }
            },
        }));
        addSwiperEntry('#catalog-swiper', __assign(__assign(__assign({}, defaultSwiperSettings), noSwiperNavigation), {
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop: false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 3.5
                },
                1440: {
                    spaceBetween: 24,
                    slidesPerView: 5
                },
            }
        }));
        addSwiperEntry('#static-product-swiper', __assign(__assign(__assign({}, defaultSwiperSettings), noSwiperNavigation), {
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop: false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 4
                },
            }
        }));
        for (var _i = 0, _a = $('[id*=category-swiper]').toArray(); _i < _a.length; _i++) {
            var swiper = _a[_i];
            addSwiperEntry('#' + swiper.id, __assign(__assign({}, defaultSwiperSettings), noSwiperNavigation));
        }
        addSwiperEntry('#best-sellers-swiper', __assign(__assign(__assign({}, defaultSwiperSettings), noSwiperNavigation), {
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop: false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 4
                },
            }
        }));
        addSwiperEntry('#most-searched-swiper', __assign(__assign({}, defaultSwiperSettings), noSwiperNavigation));
        addSwiperEntry('#brand-image-swiper', __assign(__assign({}, defaultSwiperSettings), noSwiperNavigation));
        addSwiperEntry('#product-images-swiper', __assign(__assign(__assign({}, defaultSwiperSettings), noSwiperNavigation), swiperLoop));
        addSwiperEntry('#same-brand-products-swiper', __assign(__assign(__assign({}, defaultSwiperSettings), noSwiperNavigation), swiperLoop));
        addSwiperEntry('#same-category-products-swiper', __assign(__assign(__assign({}, defaultSwiperSettings), noSwiperNavigation), swiperLoop));
        // END: Custom swipers
        var uninitializedSwipers = $('.swiper-container').not('.swiper-container-initialized').toArray();
        for (var _b = 0, _c = indexValuePairs(uninitializedSwipers); _b < _c.length; _b++) {
            var _d = _c[_b], index = _d[0], swiper = _d[1];
            var id = $(swiper).attr('id');
            if (not(id)) {
                swiper.id = "generic-swiper-".concat(index);
                addSwiperEntry("#".concat(swiper.id), defaultSwiperSettings);
            }
            else if (not(swipers[id])) {
                addSwiperEntry("#".concat(id), defaultSwiperSettings);
            }
        }
    });
}
