function headerComponent() {
    $('header')
        .on('toggle:was-opened', function(event, selector) {
            if (selector == '#menu') {
                const menuItems = $('#menu .menu-item');
                menuItems.first().addClass('selected');
                menuItems.one('mouseover', function() {
                    menuItems.removeClass('selected');
                });
            }
            else if (selector == '#mobile-search') {
                $('#mobile-search .search-input')[0].focus();
            }
        });

    $('#mobile-search').on('click', function(event) {
        const target = $(event.target);
        if (!target.is('.search-container') && !exists(target.closest('.search-container'))) {
            $('#mobile-search-toggle').trigger('click');
        }
    });

    setHeaderHeight();
    $(window).on('resize orientationchange', setHeaderHeight);

    function setHeaderHeight() {
        document.body.style.setProperty('--header-height', document.querySelector('header').offsetHeight.toString()+'px');
    }
}

