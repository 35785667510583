function faqPage() {
    $('.faq-section').each(function (idx) {
        var section = $(this);
        var sectionId = "faq-section-".concat(idx);
        section
            .attr('id', sectionId)
            .find('.faq .toggle').each(function (idx) {
            var toggle = $(this);
            var answer = toggle.siblings('.answer');
            var answerId = "".concat(sectionId, "-answer-").concat(idx);
            answer.attr('id', answerId);
            toggle
                .attr('data-toggle', "#".concat(answerId))
                .attr('data-toggle-slide', '')
                .attr('data-toggle-exclude', "#".concat(sectionId, " .answer"));
        });
    });
}
function openFaqAnswer(answerId) {
    var answer = $(answerId);
    if (exists(answer)) {
        var toggle = answer.siblings('.toggle');
        answer.trigger('toggle:open');
        window.scrollToElement(toggle);
    }
}
