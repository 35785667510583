declare var messageBoxDuration: number;

/**
 * Show messagebox of type "type", displaying "message".
 * @param {'success' | 'danger'} type Type of the messagebox
 * @param {string} message Message to display
 * @param {() => void} onClose Optional callback function to be called on messagebox closing
 */
function showMessageBox(type: 'success' | 'danger', message: string, onClose: () => void = () => { }) {
    const alertBox = $('.alert-box');
    const alertIsDialog = alertBox.is('dialog');
    const timestamp = Date.now();
    const messageBox = `
        <div class="alert alert-${type}">
            ${message}
        </div>
    `;

    alertBox.data('timestamp', timestamp);
    if (alertIsDialog) {
        alertBox.children('div').html(messageBox);
        alertBox.trigger('open');
        if (!alertBox.is('[open]')) {
            //@ts-ignore until typescript DOM type definitions are updated
            (alertBox[0] as HTMLDialogElement).showModal();
        }
    }
    else {
        alertBox.html(messageBox).show();
    }
    setTimeout(() => {
        closeMessageBox(timestamp);
        onClose();
    }, typeof messageBoxDuration === "undefined" ? 5000 : messageBoxDuration);
    
    function closeMessageBox(timestamp = 0) {
        const alertBox = $('.alert-box');
        const alertIsDialog = alertBox.is('dialog');
        const alertBoxTimestamp = alertBox.data('timestamp') || 0;
        if (alertBoxTimestamp == timestamp) {
            if (alertIsDialog) {
                //@ts-ignore until typescript DOM type definitions are updated
                (alertBox[0] as HTMLDialogElement).close();
            }
            else {
                alertBox.html('').hide();
            }
        }
    }
}

/**
 * Opens a messagebox prompting the user to confirm some action.
 * @param {string} message Message to display
 * @param {() => void} onConfirm Function to call if confirm button is clicked
 * @param {() => void} onClose Optional function to call if the box is closed or the cancel button is clicked
 */
function showQuestionBox(message: string, onConfirm: () => void, onClose: () => void = () => {}) {
    const alertBox = $('.alert-box');
    const alertIsDialog = alertBox.is('dialog');
    const messageBox = alertIsDialog ?
    `
        <div class="alert alert-question">
            <span>${message}</span>
            <div class="buttons-container">
                <button type="submit" class="btn btn-secondary width-100 cancel-button">` +
                    //@ts-ignore "msg" is guaranteed to exist
                    `${msg['LABEL_CANCEL']}
                </button>
                <button type="submit" class="btn btn-primary width-100 confirm-button">` +
                    //@ts-ignore "msg" is guaranteed to exist
                    `${msg['LABEL_CONFIRM']}
                </button>
            </div>
        </div>
    ` : 
    `
        <div class="alert alert-question">
            ${message}
        </div>
        <div class="buttons-container">
            <button type="submit" class="btn btn-secondary width-100 cancel-button">` +
                //@ts-ignore "msg" is guaranteed to exist
                `${msg['LABEL_CANCEL']}
            </button>
            <button type="submit" class="btn btn-primary width-100 confirm-button">` +
                //@ts-ignore "msg" is guaranteed to exist
                `${msg['LABEL_CONFIRM']}
            </button>
        </div>
    `;
    
    alertBox.addClass('question').html(messageBox);
    
    if (alertIsDialog) {
        alertBox.trigger('open');
        if (!alertBox.is('[open]')) {
            //@ts-ignore until typescript DOM type definitions are updated
            (alertBox[0] as HTMLDialogElement).showModal();
        }
    }
    else {
        alertBox.show();
        blockPageScroll();
    }

    $('.alert-box .cancel-alert-box, .alert-box .cancel-button').on('click', function () {
        alertBox.removeClass('question').html('');
        if (alertIsDialog) {
            alertBox.trigger('open');
            //@ts-ignore until typescript DOM type definitions are updated
            (alertBox[0] as HTMLDialogElement).close();
        }
        else {
            alertBox.hide();
            restorePageScroll();
        }
        onClose();
    });
    //@ts-ignore until typescript DOM type definitions are updated
    $('.alert-box .confirm-button').on('click', alertIsDialog ? () => { onConfirm(); (alertBox[0] as HTMLDialogElement).close(); } : onConfirm);
}