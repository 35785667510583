var BgLazyLoader: IntersectionObserver;

type MediaQueryDimension = {
    val: number,
    dir: 'up' | 'down'
}

type ImageSet = {
    width: MediaQueryDimension,
    height: MediaQueryDimension,
    val: string
};

/**
 * Initializes the BgLazyLoader IntersectionObserver, which will load the background images of <div> tags with the data-lazy-load attribute.
 * @param {JQuerySelector} scope Scope within which enable background image lazy load
 */
function initBackgroundImageLazyLoad(scope: JQuerySelector = document) {
    const lazyBgImages = $(scope).find('.background-image[data-lazy-load]').not(initialized);
    if (exists(lazyBgImages)) {
        if (BgLazyLoader === undefined) {
            BgLazyLoader = new IntersectionObserver(bgImages => {
                for (const background of bgImages.map(bg => $(bg.target as HTMLElement))) {
                    if (background.data('lazy-bg-initialized') === undefined) {
                        const src = background.data('src') as string;
                        if (src.includes('::')) {
                            let queries: {widths: MediaQueryDimension[], heights: MediaQueryDimension[], sets: ImageSet[]} = {
                                widths: [],
                                heights: [],
                                sets: []
                            };
                            for (const entry of src.split(';')) {
                                const [media, set] = entry.split('::');
                                const mediaValue = parseInt(media.split(':')[1].replace('px', ''));
                                let setObj: ImageSet = {
                                    width: {val: 0, dir: 'down'},
                                    height: {val: 0, dir: 'down'},
                                    val: set
                                }
    
                                if (media.includes('min-width')) {
                                    queries.widths.push({val: mediaValue, dir: 'up'});
                                    setObj.width = {val: mediaValue, dir: 'up'};
                                }
                                else if (media.includes('max-width')) {
                                    queries.widths.push({val: mediaValue, dir: 'down'});
                                    setObj.width = {val: mediaValue, dir: 'down'};
                                }
                                else if (media.includes('min-height')) {
                                    queries.heights.push({val: mediaValue, dir: 'up'});
                                    setObj.height = {val: mediaValue, dir: 'up'};
                                }
                                else if (media.includes('max-height')) {
                                    queries.heights.push({val: mediaValue, dir: 'down'});
                                    setObj.height = {val: mediaValue, dir: 'down'};
                                }
                                queries.sets.push(setObj);
                            }
    
                            function setImageSrc() {
                                if (queries.widths.length > 0 || queries.heights.length > 0) {
                                    const windowWidth = window.innerWidth;
                                    const windowHeight = window.innerHeight;
                                    
                                    let width: MediaQueryDimension, height: MediaQueryDimension;
        
                                    for (let queryWidth of queries.widths) {
                                        const queryCheck = (queryWidth.dir === 'up') ? windowWidth >= queryWidth.val : windowWidth <= queryWidth.val;

                                        if (!queryCheck) {
                                            break;
                                        }

                                        width = queryWidth;
                                    }
                                    for (let queryHeight of queries.heights) {
                                        const queryCheck = (queryHeight.dir === 'up') ? queryHeight.val >= windowHeight : queryHeight.val <= windowHeight;
                                        
                                        if (!queryCheck) {
                                            break;
                                        }

                                        height = queryHeight;
                                    }
        
                                    const set = queries.sets.filter(set => {
                                        return (
                                            (!width || (width.val == set.width.val && width.dir == set.width.dir)) &&
                                            (!height || (height.val == set.height.val && height.dir == set.height.dir))
                                        );
                                    });

                                    if (set.length > 0) {
                                        background.css('background-image', set[0].val);
                                    }

                                    console.log([width, height], set[0])
                                }
                                if (background.css('background-image') == 'none') {
                                    background.css('background-image', `url("${background.data('src-fallback')}")`);
                                }
                            }
    
                            $(window).on('resize orientationchange', setImageSrc);
                            setImageSrc();
                        }
                        else {
                            background.css('background-image', `url(${background.data('src')})`);
                        }
                        
                        background.find('.alt').css('display', 'none');
                        background.data('lazy-bg-initialized', true);
                    }
                }
            });
        }
        
        for (const bgImage of lazyBgImages.toArray()) {
            BgLazyLoader.observe(bgImage);
        }
    }

    function initialized() {
        return $(this).hasData('lazy-bg-initialized');
    }
}