// FIXME: @types/swiper does not work, temp dummy class & type to allow compilation
declare class Swiper {
    constructor(key: string, options: SwiperOptions)
    on: (event: string, handler: () => void) => void
    slideToLoop: (index: number, duration: number) => void
    realIndex: number
    update: () => void
};
declare type SwiperOptions = object;

var swipers: {[id: string]: Swiper} = {};

var defaultSwiperSettings = {
    slidesPerView: 1,
    centerInsufficientSlides: true,
    spaceBetween: 20,
    autoplay: {
        delay: 2000,
      },
    speed: 1500,
    breakpoints: {
        641: {
            slidesPerView: 2
        },
        1025: {
            slidesPerView: 4
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};

var noSwiperNavigation = {
    navigation: {},
};

var swiperLoop = {
    loop: true,
};

var addSwiperEntry: (key: string, settings: SwiperOptions, eventHandlers?: {[event: string]: () => void}) => void;

function swiperComponent() {
    requireModule('swiper', function() {

        addSwiperEntry = (key: string, settings: SwiperOptions, eventHandlers: {[event: string]: () => void} = {}) => {
            if (exists(key) && not(swipers[key])) {
                swipers[key] = new Swiper(key, settings);
        
                for (let [event, handler] of Object.entries(eventHandlers)) {
                    swipers[key].on(event, handler);
                }
            }
        }

        // BEGIN: Custom swipers

        addSwiperEntry('#brands-swiper', {...defaultSwiperSettings, ...noSwiperNavigation, ...{
            slidesPerView: 2.5,
            spaceBetween: 8,
            loop: true,
            speed: 2500,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 3.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 5
                }
            },
        }});

        addSwiperEntry('#catalog-swiper', {...defaultSwiperSettings,...noSwiperNavigation, ...{
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop:false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 3.5
                },
                1440: {
                    spaceBetween: 24,
                    slidesPerView: 5
                },
            }
        }});

        addSwiperEntry('#static-product-swiper', {...defaultSwiperSettings,...noSwiperNavigation, ...{
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop:false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 4
                },
            }
        }});

        for (const swiper of $('[id*=category-swiper]').toArray()) {
            addSwiperEntry('#' + swiper.id, {...defaultSwiperSettings, ...noSwiperNavigation});
        }

        addSwiperEntry('#best-sellers-swiper', {...defaultSwiperSettings,...noSwiperNavigation, ...{
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop:false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 4
                },
            }
        }});
        addSwiperEntry('#most-searched-swiper', {...defaultSwiperSettings, ...noSwiperNavigation});
        addSwiperEntry('#brand-image-swiper', {...defaultSwiperSettings, ...noSwiperNavigation});
        addSwiperEntry('#product-images-swiper', {...defaultSwiperSettings, ...noSwiperNavigation, ...swiperLoop});
        addSwiperEntry('#same-brand-products-swiper', {...defaultSwiperSettings, ...noSwiperNavigation, ...swiperLoop});
        addSwiperEntry('#same-category-products-swiper', {...defaultSwiperSettings, ...noSwiperNavigation, ...swiperLoop});

        // END: Custom swipers

        const uninitializedSwipers = $('.swiper-container').not('.swiper-container-initialized').toArray();
        
        for (let [index, swiper] of indexValuePairs(uninitializedSwipers)) {
            const id = $(swiper).attr('id');

            if (not(id)) {
                swiper.id = `generic-swiper-${index}`;
                addSwiperEntry(`#${swiper.id}`, defaultSwiperSettings);
            }
            else if (not(swipers[id])) {
                addSwiperEntry(`#${id}`, defaultSwiperSettings);
            }
        }
    });
}