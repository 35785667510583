/**
 * Initializes multi-step elements within "scope". Use guide:
 * 
 * Terminology:
 *      container: element with the .multi-step-container class
 *      step: element within the container with the .step class and the data-step attribute
 *      step identifier: value of a step's data-step attribute
 *      step-related: element within the contained with the .step-related class and the data-step attribute, they are correlated to the step with the same step identifier
 *      next button: element within the container with the .btn-next class, will emit 'multi-step:next' on click
 *      prev button: element within the container with the .btn-prev class, will emit 'multi-step:prev' on click
 * 
 * Container attributes/data:
 *      data-current-step="identifier": Indicates that element with step identifier "identifier" is the currently active one
 *      data-step-list: Contains a list of step identifiers of all steps, created after initialization
 *      data-first-step: Contains the identifier of the first step, created after initialization
 *      data-current-step-index: Contains the index of the current step in data-step-list, created after initialization
 * 
 * Step/Step-related attributes:
 *      data-step="identifier": Indicates the indentifier of the step
 * 
 * Step/Step-related classes:
 *      .active: Added/removed whether the step identifier of the current step matches the one of the step/step-related element.
 * 
 * Next button classes:
 *      .active: Always present unless the current step is the last.
 * 
 * Prev button classes:
 *      .active: Always present unless the current step is the first.
 * 
 * Container events:
 *      'multi-step:next': go to the next step (if possible).
 *      'multi-step:prev': go to the previous step (if possible).
 *      'multi-step:go-to'(identifier): go to the step with the provided "identifier" (if possible).
 * 
 * @param scope Selector of the element within which activate tabs
 */
function initMultiStep(scope: JQuerySelector = document) {
    $(scope).find('.multi-step-container').not(initialized).each(function() {
        const container = $(this);
        const initialStep = container.data('current-step');
        const steps = container.find('.step').toArray();
        const stepIds = steps.map(step => $(step).data('step'));

        container.data('multi-step-initialized', true);

        container.data('step-list', stepIds);
        container.data('first-step', stepIds[0]);
        container.data('last-step', stepIds.slice(-1)[0]);
        container.data('current-step-index', stepIds.indexOf(initialStep));

        checkIfFirstOrLast();
        function checkIfFirstOrLast() {
            if (container.data('current-step') == container.data('first-step')) {
                container.addClass('first-step');
                container.find('.btn-prev').prop('disabled', true);
            }
            else {
                container.removeClass('first-step');
                container.find('.btn-prev').prop('disabled', false);
            }

            if (container.data('current-step') == container.data('last-step')) {
                container.addClass('last-step');
                container.find('.btn-next').prop('disabled', true);
            }
            else {
                container.removeClass('last-step');
                container.find('.btn-next').prop('disabled', false);
            }
        }

        container.addClass(getStepClass());
        function getStepClass() {
            return 'step-' + (container.data('current-step') + '').toLowerCase().replace(/\s/g, '-');
        }
        
        function updateStep(newIndex: number) {
            container.removeClass(getStepClass());

            container.data('current-step-index', newIndex);
            container.data('current-step', stepIds[newIndex]);

            container.addClass(getStepClass());
        }

        setCurrentStep();
        function setCurrentStep() {
            container.find('.step, .step-related').removeClass('active')
                .filter(function() { return $(this).data('step') == container.data('current-step'); })
                    .addClass('active');
        }

        container
            .on('multi-step:next', function() {
                const index = container.data('current-step-index') >> 0;
                const nextIndex = Math.min(index + 1, stepIds.length - 1);

                if (index != nextIndex) {
                    updateStep(nextIndex);
                }
                checkIfFirstOrLast();
                setCurrentStep();
            })
            .on('multi-step:prev', function() {
                const index = container.data('current-step-index') >> 0;
                const prevIndex = Math.max(index - 1, 0);

                if (index != prevIndex) {
                    updateStep(prevIndex);
                }
                checkIfFirstOrLast();
                setCurrentStep();
            })
            .on('multi-step:go-to', function(event, step) {
                const newIndex = stepIds.indexOf(step);

                if (newIndex >= 0) {
                    updateStep(newIndex);
                }
                checkIfFirstOrLast();
                setCurrentStep();
            });
    });

    $('.multi-step-container .btn-next').on('click', function() {
        $(this).trigger('multi-step:next');
    });
    $('.multi-step-container .btn-prev').on('click', function() {
        $(this).trigger('multi-step:prev');
    });

    function initialized() {
        return $(this).hasData('multi-step-initialized');
    }
}