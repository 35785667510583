var rewixUpdateCart = function (elem, remove) {
    if (remove === void 0) { remove = false; }
    var RewixIsDefined = typeof Rewix !== 'undefined';
    try {
        if (RewixIsDefined) {
            if (Rewix.trackUpdateCart) {
                if (remove)
                    matomoUpdateCartRemove(elem);
                else
                    matomoUpdateCart(elem);
            }
            else if (Rewix.trackAddToCart) {
                Rewix.trackAddToCart('', 1);
            }
        }
    }
    catch (error) {
        console.error(error);
    }
};
function matomoUpdateCart(elem) {
    var qtys = $(elem).find('[data-matomo]').toArray();
    for (var _i = 0, qtys_1 = qtys; _i < qtys_1.length; _i++) {
        var input = qtys_1[_i];
        var $input = $(input);
        var finalQuantity = parseInt($input.value());
        var _a = $input.data('matomo'), productId = _a.productId, name_1 = _a.name, modelId = _a.modelId, modelSKU = _a.modelSKU, initQuantity = _a.initQuantity, price = _a.price;
        Rewix.trackUpdateCart(productId, name_1, modelId, modelSKU, initQuantity, finalQuantity, price);
        $input.data('matomo', {
            productId: productId,
            name: name_1,
            modelId: modelId,
            modelSKU: modelSKU,
            initQuantity: finalQuantity,
            price: price
        });
    }
}
function matomoUpdateCartRemove(elem) {
    var qtys = $(elem).find('[data-matomo]').toArray();
    for (var _i = 0, qtys_2 = qtys; _i < qtys_2.length; _i++) {
        var input = qtys_2[_i];
        var $input = $(input);
        var finalQuantity = 0;
        var _a = $input.data('matomo'), productId = _a.productId, name_2 = _a.name, modelId = _a.modelId, modelSKU = _a.modelSKU, initQuantity = _a.initQuantity, price = _a.price;
        Rewix.trackUpdateCart(productId, name_2, modelId, modelSKU, initQuantity, finalQuantity, price);
    }
}
