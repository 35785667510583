function subscriptionPage() {

    bindFormSubmitEvent();

    $(window).on('popstate', function() {
        const value = getQueryParam('option') as string;
        debounce('subscribe-page:popstate', () => {
            $('#subscription-select').val(value);
            reloadSubscription(value);
        }, 500);
    });

    $('#subscription-select').on('change', function() {
        const value = $(this).value<string>();
        const url = buildUrl({queryParams: {option: value}});
        history.pushState(null, null, url);
        reloadSubscription(value);
    });

    function reloadSubscription(value: string) {
        const url = buildUrl({queryParams: {option: value}});
        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'html',
            cache: true,
            success: function(data) {
                const response = $('<div>').append(data);
                const wrapper = response.find('#subscription-wrapper');
                $('#subscription-wrapper').replaceWith(wrapper);
                bindFormSubmitEvent();
            },
            error: function() {
                setQueryParam('option', value);
            }
        });
    }

    function bindFormSubmitEvent() {
        $('#monthly-subscription-form').validate({
            submitHandler: function (form) {
                const $form = $(form);

                $.ajax({
                    method: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        $form.find(':input').prop('disabled', true);
                    },
                    success: function (data) {
                        if (data.success) {
                            initializePayment(data.data, $form.data('nexturl'), $form);
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    },
                    error: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                    complete: function () {
                        $form.find(':input').prop('disabled', false);
                    }
                });
            }
        });
        
        $('#yearly-subscription-form').validate({
            submitHandler: function (form) {
                const $form = $(form);

                $.ajax({
                    method: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        $form.find(':input').prop('disabled', true);
                    },
                    success: function (data) {
                        if (data.success) {
                            initializePayment(data.data, $form.data('nexturl'), $form);
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    },
                    error: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                    complete: function () {
                        $form.find(':input').prop('disabled', false);
                    }
                });
            }
        });
    }
}

function initializePayment(orderId: number | string, nextUrl: string, form: JQuery<Element>) {
    $.ajax({
        type: "POST",
        url: '/restful/shopping/checkout/payment/initialize',
        data: { orderId: orderId },
        dataType: 'json',
        success: function(data) {
            if (data.success) {
                const selectedOption = form;
                if (selectedOption) {
                    const matomoDataSignup = selectedOption.data('matomo-signup');
                    const matomoDataRecurring = selectedOption.data('matomo-recurring');
                    const dummyElement = $(`
                        <div>` +
                        (matomoDataSignup ? `
                            <input type="hidden" value="1" data-matomo='${JSON.stringify(matomoDataSignup)}'>
                        ` : '') + `
                            <input type="hidden" value="1" data-matomo='${JSON.stringify(matomoDataRecurring)}'>
                        </div>
                    `);
                    rewixUpdateCart(dummyElement);
                }
                window.location.href = nextUrl;
            }
            else {
                alert('Cannot pay this order.');
            }
        },
        error: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        }
    });
}