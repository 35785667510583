function productComponent() {
    loadQtyInputs();

    $('.wishlist-container .add-to-wishlist').on('submit', function(event) {
        event.preventDefault();

        const $form = $(this);
        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_ADDED_TO_WISHLIST']);

                    if (window.dataLayer) {
                        const modelId = $form.find('[name*="qty_model"]').attr('name').replace('qty_model_', '');
                        dataLayer.push({ ecommerce: null });
                        dataLayer.push({
                            event: 'add_to_wishlist',
                            ecommerce: (window as any).ecommerce[modelId]
                        });
                    }

                    $form.addClass('hide');
                    $form.closest('.wishlist-container')
                        .find('.remove-from-wishlist').removeClass('hide');
                    $form.trigger('wishlist:add');
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.wishlist-container .remove-from-wishlist').on('submit', function(event) {
        event.preventDefault();

        const $form = $(this);
        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_REMOVED_FROM_WISHLIST']);
                    $form.addClass('hide');
                    $form.closest('.wishlist-container')
                        .find('.add-to-wishlist').removeClass('hide');
                    $form.trigger('wishlist:remove');
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.addtocart-form')
        .on('input:quantity-changed', function() {
            $(this).find('[type=submit]').prop('disabled', false);
        })
        .on('input:quantity-not-changed', function() {
            $(this).find('[type=submit]').prop('disabled', true);
        })
        .on('submit', function(event) {
            event.preventDefault();

            const $form = $(this);
            $.ajax({
                method: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function() {
                    $form.find('input').prop('disabled', true);
                },
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_ADDEDTOCART']);
                        rewixUpdateCart($form);
                        $form.find('.quantity-input').each(function() {
                            const value = $(this).val();
                            $(this).data('qtyorig', value);
                        });
                        $form.find('[type=submit]').prop('disabled', true);
                        $form.trigger('addtocart:success');
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        $form.trigger('addtocart:fail');
                    }
                },
                error: function() {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function() {
                    $form.find('input').prop('disabled', false);
                    $form.trigger('change');
                }
            });
        });

    $('.addtocart-form .options-radio .radio').on('click', function() { updateCurrentModel(this); });
    $('.addtocart-form .options-select').on('change', function() { updateCurrentModel(this); });
}