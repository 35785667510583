interface JQuery {
    value<T>(caster?: (val: string) => T): T,
    dataAs<T>(data: string, caster?: (val: string) => T): T,
    disabled(setting: boolean): JQuery,
    required(setting: boolean): JQuery,
    label(): string,
    label(content: string): JQuery,
    hasData(data: string | string[]): boolean,     // Not to be confused with jQuery.hasData()
    hasData(data: string, value: string): boolean, // Not to be confused with jQuery.hasData()
    withData(data: string | string[]): JQuery,
    withData(data: string, value: string): JQuery,
    withData(data: string, condition: (data: string) => boolean): JQuery,
    hasEventHandler(event: string): boolean,
    onLast(events: string, delay: number, handler: ($el: JQuery) => void): JQuery
};

$.fn.extend({
    value: function <T>(caster?: (val: string) => T) {
        if (caster) {
            return caster($(this).first().val().toString())
        }
        else {
            return $(this).first().val() as any as T;
        }
    },
    dataAs: function <T>(data: string, caster?: (val: string) => T) {
        if (caster) {
            const dataValue = $(this).first().data(data);
            return caster(dataValue == null ? '' : dataValue)
        }
        else {
            return $(this).first().data(data) as any as T;
        }
    },
    disabled: function (setting: boolean) {
        return $(this).prop('disabled', setting);
    },
    required: function (setting: boolean) {
        return $(this).prop('required', setting);
    },
    label: function (content?: string) {
        const label = $(this).siblings('label').first();
        if (content) {
            label.text(content);
            return $(this);
        }
        else {
            return label.text().trim();
        }
    },
    hasData: function (data: string | string[], value: string) {
        const $this = $(this);
        if (Array.isArray(data)) {
            for (const datum of data) {
                if ($this.data(datum) === undefined) {
                    return false;
                }
            }
            return true;
        }
        else if (value) {
            return $this.data(data) == value;
        }
        else {
            return $this.data(data) !== undefined;
        }
    },
    withData: function (data: string | string[], value_or_condition: string | ((data: string | string[]) => boolean)) {
        return $(this).filter(function() {
            if (!Array.isArray(data) && value_or_condition) {
                if (value_or_condition instanceof Function) {
                    return value_or_condition($(this).data(data));
                }
                else {
                    return $(this).hasData(data, value_or_condition);
                }
            }
            else {
                return $(this).hasData(data);
            }
        });
    },
    hasEventHandler: function (event: string) {
        //@ts-ignore $._data is a private, but existing method
        const events = ($._data($(this).get(0), 'events') || {}) as map<string, object>;
        return event in events;
    },
    onLast: function (events: string, delay: number, handler: ($el: JQuery) => void) {
        return $(this).on(events, function() {
            const $this = $(this);
            const timestamp = Date.now();
            $this.data('timestamp', timestamp)
            setTimeout(function() {
                if ($this.data('timestamp') == timestamp) {
                    handler($this);
                }
            }, delay);
        })
    }
});