function toolsExport() {
    $<HTMLFormElement>('#easydrop-form').validate({
        submitHandler: form => {
            const extension = $('#filetype').value();
            const dummyForm = $<HTMLFormElement>(`<form method="${form.method}" action="${form.action}.${extension}"></form>`);
            $(form).find<HTMLInputElement | HTMLSelectElement>('[type="hidden"], select').each(function() {
                if (this.value.length > 0 && this.value != 'null') {
                    dummyForm.append(`<input type="hidden" name="${this.name}" value="${this.value}">`);
                }
            });
            $(document.body).append(dummyForm);
            dummyForm[0].submit();
            dummyForm[0].remove();
        }
    });
}