/**
 * Initializes input masks within "scope". Use guide:
 * 
 * Terminology:
 *      masked input: input with the data-mask attribute
 *      mask: regex parameter of data-mask, will filter values of the maksed input that do not match it
 * 
 * Search input attributes:
 *      data-mask="mask": Indicates that this element will be a masked input with mask "mask"
 * 
 * @param scope Selector of the element within which activate input masks
 */
function initInputMasks(scope: JQuerySelector = document) {
    $(scope).find('input').withData('mask').not(initialized)
        .each(function() {
            $(this).data('input-mask-initialized', true);
        })
        .on('input', function() {
            const $this = $(this);
            const mask = $this.data('mask');
            const re = new RegExp(`((?!${mask}).)`, 'gm');
            const value = $this.value<string>().replace(re, '');
            $this.val(value);
        });

    function initialized() {
        return $(this).hasData('input-mask-initialized');
    }
}