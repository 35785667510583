var debounceRegistry: map<string, DebounceEntry> = {};

/**
 * Applies debouncing to the function(s) "func" associated to "key", i.e. ensures that they are run at most once every "delay" ms.
 * @param {string} key Unique key identifying the function(s) to debounce
 * @param {Function} func Function to debounce
 * @param {number} delay Delay (in ms) after which "func" will be run
 * @param {'first' | 'latest'} policy Debounce policy, 'first' will make the first "func" passed within "delay" to run, 'latest' will cause "func" to be updated at every call; defaults to 'latest'
 */
function debounce(key: string, func: Function, delay: number, policy: 'first' | 'latest' = 'latest') {
    if (!(key in debounceRegistry)) {
        debounceRegistry[key] = {
            func: func,
            policy: policy
        };
        setTimeout(() => {
            debounceRegistry[key].func();
            delete debounceRegistry[key];
        }, delay);
    }
    else {
        if (debounceRegistry[key].policy == 'latest') {
            debounceRegistry[key].func = func;
        }
    }
}