/**
 * Initialized intl-tel-input elements in the page if any applicable input is found (name includes 'tel' or 'cel').
 */
function initTelDropdowns() {
    if (exists('input[name*="cel"]:not([type="hidden"])')) {
        requireModule('intl-tel-input', function () {
            var _loop_1 = function (type) {
                var prefixInput = $("input[name*=\"".concat(type, "\"][type=\"hidden\"]"));
                var input = $("input[name*=\"".concat(type, "\"]")).not('[type="hidden"]');
                if (exists(input)) {
                    input.val(prefixInput.value() + input.value());
                    var iti_1 = intlTelInput(input[0], {
                        utilsScript: "/skins/vendor/intl-tel-input-utils.js",
                        separateDialCode: true
                    });
                    input.on('countrychange', function () {
                        prefixInput.val(iti_1.getSelectedCountryData().dialCode);
                    });
                }
            };
            for (var _i = 0, _a = ['cel', 'tel']; _i < _a.length; _i++) {
                var type = _a[_i];
                _loop_1(type);
            }
        });
    }
}
