/**
 * Initializes event emitters within "scope". Use guide:
 * 
 * Terminology:
 *      event emitter: element with the data-emit-event attribute
 *      emittee: event emitted by the event emitter, may optionally carry data
 *      trigger: event type specified by the data-emit-event-trigger attribute, 'click' by default
 *      data: data that will be carried by the emittee, specified by data-emit-event-data attribute
 * 
 * Event emitter attributes:
 *      data-emit-event="event": Indicates that this element will be an event emitter with emittee of type "event"
 *      data-emit-event-trigger="trigger": event emitter will launch the emittee in response to the "trigger" event, 'click' by default
 *      data-emit-event-data="data": "data" to be carried by the emittee
 * 
 * @param scope Selector of the element within which activate event emitters
 */
function initEventEmitters(scope: JQuerySelector = document) {
    $(scope).find('[data-emit-event]').not(initialized).each(function() {
        const eventEmitter = $(this);
        const event = eventEmitter.data('emit-event');
        const trigger = eventEmitter.data('emit-event-trigger') || 'click';
        const data = eventEmitter.data('emit-event-data');

        eventEmitter.data('event-emitter-initialized', true)

        if (event) {
            eventEmitter.on(trigger, function() {
                if (data) {
                    eventEmitter.trigger(event, data);
                }
                else {
                    eventEmitter.trigger(event)
                }
            })
        }
    });

    function initialized() {
        return $(this).hasData('event-emitter-initialized');
    }

}