function wishlistPage() {

    $('.remove-from-wishlist').on('submit', function(event) {
        event.preventDefault();

        const $form = $(this);
        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.reload();
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.product-container').on('addtocart:success', function() {
        const container = $(this);
        setTimeout(() => container.find('.remove-from-wishlist [type=submit]')[0].click(), messageBoxDuration / 2);
    });
}