function catalogPage() {
    'use strict';

    $('html').on('catalog:reload', reloadCatalog);
    $(window)
        .on('popstate', reloadCatalog)
        .on('resize orientationchange', checkIfFiltersOpen);

    setupCatalog();
    $('#catalog-container').removeClass('loading');

    setHeaderHeight();
    $(window).on('resize orientationchange', setHeaderHeight);

    function setupCatalog() {
        initToggle('#catalog-wrapper');
        initSearch('#catalog-wrapper');
        initBackgroundImageLazyLoad('#catalog-wrapper');
        setLayoutOptions();
        setSortOptions();
        setFilterOptions();
        setPagination();
        checkIfFiltersOpen();
    }

    function setHeaderHeight() {
        document.body.style.setProperty('--header-height', document.querySelector('header').offsetHeight.toString());
    }

    function checkIfFiltersOpen() {
        if (window.outerWidth <= 1024) {
            $('#filters')
                .css('transition', 'transform 0s ease-in-out')
                .trigger('toggle:close')
                .find('.dropdown-content')
                    .trigger('toggle:close');
            /*FIXME*/
            setTimeout(() => $('#filters').css('transition', ''), 1000);
        }
        else {
            $('#filters')
                .css('transition', 'transform 0s ease-in-out')
                .trigger('toggle:open')
                .find('.dropdown-content')
                    .trigger('toggle:open');
            /*FIXME*/
            setTimeout(() => $('#filters').css('transition', ''), 1000);
        }
    }

    function reloadCatalog() {
        $('#catalog-container').addClass('loading');
        replacePageElements(
            ['#catalog-wrapper'],
            () => {
                productComponent();
                setupCatalog();
            },
            {
                error: function () {
                    window.location.reload();
                },
                complete: function () {
                    $('#catalog-container').removeClass('loading');
                }
            }
        );
    }

    function pushStateAndReload(event: JQuery.Event) {
        const value = $(this).value<string>();
        const href = $(this).attr('href');
        const url = value || href;

        if (!value) {
            event.preventDefault();
        }

        window.history.pushState({}, '', url);
        $(this).trigger('catalog:reload');
    }

    
    function setLayoutOptions() {
        $('#layout-options input[name="options"]').on('change', function () {
            const layout = $(this).value<string>();
            createCookie('catalog-layout', layout);
            reloadCatalog();
        });
    }


    function setSortOptions() {
        $('#sort-options a').on('click', pushStateAndReload);
    }


    function setFilterOptions() {
        $('#filters-row [id*="filter"]').on('change', pushStateAndReload);
        $('#filters .filter > a, .current-filter').on('click', pushStateAndReload);
        $('#filters .see-all').on('click', function() {
            $(this).closest('.filters-list').addClass('scroll-view')
                .find('.filter.hide').removeClass('hide');
            $(this).hide();
        });

        $('#mobile-filters .title').on('click', function () {
            const id = $(this).parent().attr('id');
            const menu = $(this).siblings('ul');
            $(this).toggleClass('active');
            menu.slideToggle();
            $('#mobile-filters-container').children().not(`[id=${id}]`).slideToggle();
        });

        $('#mobile-filters [id*="filter"] a')
            .on('click', function () { $(this).trigger('side-panel:close', '#mobile-filters') })
            .on('click', pushStateAndReload);
    }

    
    function setPagination() {
        $('.pagination a')
            .on('click', pushStateAndReload)
            .on('click', () => $(window).scrollTop(0));
    }
}