var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * Checks if "selector" exists in the current page.
 * @param {JQuerySelector} selector : Any valid JQuery selector
 * @returns {boolean} Whether "selector" exists or not
 */
function exists(selector) {
    try {
        return $(selector).length > 0;
    }
    catch (_a) {
        return false;
    }
}
/**
 * Negates "predicate"; also returns false if "predicate" is the string "false" (case insensitive).
 * @param {boolean | string | any} predicate Predicate to negate
 * @returns {boolean} Logical opposite of "predicate"
 */
function not(predicate) {
    return !predicate || (typeof predicate === 'string' && predicate.toLowerCase() === 'false');
}
/**
 * Load script from "url".
 * @param url Url of the script
 * @returns {Promise<void>} Promise that resolves if the script was downloaded successfully, rejects otherwise
 */
function loadScript(url) {
    return new Promise(function (resolve, reject) {
        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'script',
            async: true,
            success: function () { return resolve(); },
            error: function () { return reject(); }
        });
    });
}
/**
 * Load recaptcha script from Google's servers.
 * @param {JQuerySelector} selector ReCaptcha selector, '.g-recaptcha' by default
 */
function loadCaptcha(selector) {
    if (selector === void 0) { selector = '.g-recaptcha'; }
    if (exists(selector)) {
        var recaptcha_1 = $(selector);
        if (!recaptcha_1.data('loaded')) {
            loadScript('https://www.google.com/recaptcha/api.js')
                .then(function () { return recaptcha_1.data('loaded', 'true'); });
        }
    }
}
/**
 * Scrolls to the "selector" element with a smooth animation.
 * @param {JQuerySelector} selector Selector of the element ot scroll to
 * @returns {Promise<void>} Promise that resolves if the element to scroll to exists, rejects otherwise
 */
function scrollToElement(selector) {
    if (exists(selector)) {
        var offset_1 = $(selector).offset().top;
        var headerOffset_1 = exists('header') ? $('header').outerHeight() : 0;
        return new Promise(function (resolve) {
            $('html').animate({ scrollTop: offset_1 - headerOffset_1 }, resolve);
        });
    }
    else {
        return Promise.reject();
    }
}
/**
 * Monitors changes in input with name="nameToMonitor" in form "form" and checks for "condition", if true "onSuccess" is run, if false "onFail" is run.
 * @param {JQuerySelector} form Form containing the input to monitor
 * @param {string} nameToMonitor Name of the input to monitor
 * @param {(input: JQuery<HTMLElement>) => boolean} condition Predicate function with the condition to check
 * @param {() => void} onSuccess Is run if "condition" is true
 * @param {() => void} onFail Is run if "condition" is false
 */
function monitorInput(form, nameToMonitor, condition, onSuccess, onFail) {
    var $form = $(form);
    var $inputToMonitor = $form.find("[name=".concat(nameToMonitor, "]"));
    checkInput();
    $inputToMonitor.on('change', checkInput);
    function checkInput() {
        if (condition($inputToMonitor)) {
            onSuccess();
        }
        else {
            onFail();
        }
    }
}
/**
 * Disables and hides input with name="name" in form "form".
 * @param {JQuerySelector} form Form containing the input to disable
 * @param {string} name Name of the input to disable
 */
function disableInput(form, name) {
    var $form = $(form);
    var $this = $form.find("[name=".concat(name, "]"));
    var type = $this.attr('type');
    var container = '.label-input';
    if (type == 'radio') {
        container = '.radio-container';
    }
    else if (type == 'checkbox') {
        container = '.checkbox-container';
    }
    else if (type == 'search') {
        container = '.search-container';
    }
    $this.prop('disabled', true);
    $this.closest(container).hide();
}
/**
 * Enables and unhides input with name="name" in form "form".
 * @param {JQuerySelector} form Form containing the input to enable
 * @param {string} name Name of the input to enable
 */
function enableInput(form, name) {
    var $form = $(form);
    var $this = $form.find("[name=".concat(name, "]"));
    var type = $this.attr('type');
    var container = '.label-input';
    if (type == 'radio') {
        container = '.radio-container';
    }
    else if (type == 'checkbox') {
        container = '.checkbox-container';
    }
    else if (type == 'search') {
        container = '.search-container';
    }
    $this.prop('disabled', false);
    $this.closest(container).show();
}
/**
 * Transforms "array" of values of type T into an array of index-value pairs.
 * @param {T[]} array Array to transform
 * @returns {(readonly [number, T])[]} Array of index-value pairs
 */
function indexValuePairs(array) {
    return array.map(function (value, index) { return [index, value]; });
}
/**
 * Copies values from inputs in "sourceForm" to inputs in "destForm", according to the source name-destination name map "nameMap".
 * @param {JQuerySelector} sourceForm Form to copy from
 * @param {JQuerySelector} destForm Form to copy to
 * @param {map<string, string>} nameMap Map of inputs from "sourceForm" to copy to inputs in "destForm", in the form {"sourceName": "destName", ...}
 */
function copyFormInputs(sourceForm, destForm, nameMap) {
    var source = $(sourceForm);
    var dest = $(destForm);
    for (var _i = 0, _a = Object.entries(nameMap); _i < _a.length; _i++) {
        var _b = _a[_i], sourceName = _b[0], destName = _b[1];
        var sourceInput = source.find("[name=".concat(sourceName, "]"));
        var destInput = dest.find("[name=".concat(destName, "]"));
        if (exists(sourceInput) && exists(destInput)) {
            destInput.val(sourceInput.val()).trigger('change');
        }
    }
}
/**
 * Executes "fn" every "delay" ms until condition "until" is fulfilled.
 * @param {number | boolean | (() => boolean)} until Condition to be fulfilled; if the condition is a number "fn" will be executed "until" times; if it is a boolean the condition is "reversed", i.e. true will make "fn" execute forever, false wil make "fn" never execute
 * @param {number} delay Delay in ms
 * @param {() => void} fn Function to execute
 */
function strobe(until, delay, fn) {
    var condition;
    function localTimeout() {
        if (!condition()) {
            fn();
            setTimeout(localTimeout, delay);
        }
    }
    if (typeof until === 'number') {
        condition = function () {
            if (until > 0) {
                until -= 1;
                return false;
            }
            else {
                return true;
            }
        };
    }
    else if (typeof until === 'boolean') {
        condition = function () { return !until; };
    }
    else {
        condition = until;
    }
    localTimeout();
}
/**
 * Wrapper around a call to loadScript, used to load bundled script.modules in the build folder.
 * @param module Name of the module, e.g. 'swiper' will load script.module.swiper.js
 * @param callback Function to execute on script load.
 */
function requireModule(module, callback) {
    loadScript("".concat(skin, "/build/script.module.").concat(module, ".js")).then(callback);
}
/**
 * Initializes quantity inputs.
 */
function loadQtyInputs() {
    $('.quantity-input').not(initialized).each(function () {
        var $this = $(this);
        if ($this.val() > $this.data('max')) {
            $this.css('color', 'red');
        }
        $this.siblings('.btn-minus').on('click', function (event) {
            event.preventDefault();
            var value = getCurrentValue($this);
            checkValue(value - 1);
        });
        $this.siblings('.btn-plus').on('click', function (event) {
            event.preventDefault();
            var value = getCurrentValue($this);
            checkValue(value + 1);
        });
        $this.on('keydown input', function (event) {
            var value = getCurrentValue($this);
            var key = (event.key || '').toUpperCase();
            if (key == 'ENTER') {
                event.preventDefault();
                $this.closest('form').trigger('submit');
            }
            else if (key == 'ARROWUP') {
                checkValue(value + 1);
            }
            else if (key == 'ARROWDOWN') {
                checkValue(value - 1);
            }
            else {
                checkValue(value);
            }
        });
        function getCurrentValue($this) {
            return parseInt($this.value().toString().replace(/[^0-9]/g, '')) || 0;
        }
        function checkValue(value) {
            var min = $this.data('min') >> 0;
            var max = $this.data('max') == null ? Number.MAX_SAFE_INTEGER : $this.data('max');
            if (value > max) {
                $this.val(max);
            }
            else if (value < min) {
                $this.val(min);
            }
            else {
                $this.val(value);
            }
            $this.trigger('change');
        }
        $this.on('change', function () {
            var current = $this.val();
            var max = $this.data('max') == null ? Number.MAX_SAFE_INTEGER : $this.data('max');
            if (current <= max) {
                $this.css('color', '');
                var qtyorig = $this.data('qtyorig') >> 0;
                var otherInputsHaveChanged = $this.closest('form').find('.quantity-input').toArray().some(function (input) { return $(input).value() != $(input).data('qtyorig'); });
                var event_1 = (qtyorig != current || otherInputsHaveChanged) ? 'input:quantity-changed' : 'input:quantity-not-changed';
                $this.trigger(event_1);
            }
            else {
                $this.css('color', 'red');
                $this.trigger('input:quantity-not-changed');
            }
        });
        $this.data('quantity-input-initialized', true);
    });
    function initialized() {
        return $(this).hasData('quantity-input-initialized');
    }
}
/**
 * Function to bind to the event handler of an option selector.
 */
function updateCurrentModel(input, callback) {
    var $this = $(input);
    var form = $this.closest('form');
    var name = $this.attr('name');
    var models = $this.value().split(',');
    var modelId = getCurrentModelId(form);
    checkCompatibleOptions(form, name, models);
    if (modelId != null) {
        form.find('[name*=qty_model]').attr('name', "qty_model_".concat(modelId));
        if (callback) {
            callback(modelId);
        }
    }
}
/**
 * Returns the current model id, according to the selected product options
 * @param form Product addtocart form
 * @returns {string} Product id
 */
function getCurrentModelId(form) {
    var selectedOptions = form.find('.options-select option:selected, .options-radio .radio:checked');
    if (exists(selectedOptions)) {
        var modelLists = selectedOptions.toArray().map(function (elem) { return $(elem).value().split(','); });
        var model = modelLists.reduce(function (l1, l2) { return l1.filter(function (elem) { return l2.includes(elem); }); })[0];
        return model;
    }
}
/**
 * Checks the other product options, if any, and selects the first compatible ones.
 * @param form Product addtocart form
 * @param lastInputName Name of the input that triggered the model change
 * @param models Models associated to the selected option
 */
function checkCompatibleOptions(form, lastInputName, models) {
    var otherInputs = form.find(".options-select:not([name=".concat(lastInputName, "]), .options-radio:not(.name-").concat(lastInputName, ")"));
    otherInputs.find('option, .radio')
        .filter(function () {
        var optionModels = $(this).value().split(',');
        return !optionModels.some(function (model) { return models.includes(model); });
    })
        .addClass('incompatible');
    otherInputs.each(function () {
        var input = $(this);
        var incompatibleSelectedOptions = input.find('option:selected.incompatible, .radio:checked.incompatible');
        if (exists(incompatibleSelectedOptions)) {
            var firstValidOption = input.find('option:not(.incompatible), .radio:not(.incompatible)').first();
            if (firstValidOption.is('option')) {
                var val = firstValidOption.val();
                firstValidOption.closest('select').val(val);
            }
            else {
                firstValidOption.trigger('click');
            }
        }
    });
    otherInputs.find('option, .radio').removeClass('incompatible');
}
/**
 * Write "text" to the clipboard using the browser Clipboard API.
 * @param {string} text Text to write to the clipboard
 * @returns A promise resolving to true if the text is copied, false otherwise
 */
function copyTextToClipboard(text) {
    return new Promise(function (resolve) { return navigator.clipboard.writeText(text)
        .then(function () {
        resolve(true);
    })
        .catch(function () {
        resolve(false);
    }); });
}
/**
 * Replaces elements in the page corresponding to "selectors" with the ones retrieved from the AJAX call to "url" in "settings" (the current URL by default).
 * @param {string[]} selectors List of JQuery selectors corresponding to the elements to replace
 * @param {(replacedAmounts: map<string, number>) => void} callback Callback function to call after replacing the page elements
 * @param {string} settings Settings for the JQuery AJAX call
 */
function replacePageElements(selectors, callback, settings) {
    if (callback === void 0) { callback = function () { }; }
    if (settings === void 0) { settings = {}; }
    var url = settings.url, otherParams = __rest(settings, ["url"]);
    $.ajax(__assign({ method: 'GET', url: url || window.location.href, dataType: 'html', success: function (data) {
            var response = $('<div>').append(data);
            var info = {
                amounts: selectors.reduce(function (a, b) { a[b] = 0; return a; }, {}),
                replacements: selectors.reduce(function (a, b) { a[b] = []; return a; }, {}),
                response: response.clone()
            };
            for (var _i = 0, selectors_1 = selectors; _i < selectors_1.length; _i++) {
                var selector = selectors_1[_i];
                var element = response.find(selector);
                if (exists(element)) {
                    $(selector).replaceWith(element);
                    info.replacements[selector].push(element);
                    info.amounts[selector] += 1;
                }
            }
            callback(info);
        } }, otherParams));
}
/**
 * Returns true if the current browser is Internet Explorer (according to its User Agent), false otherwise.
 * @returns A boolean value
 */
function isIE() {
    var ua = window.navigator.userAgent;
    return /MSIE|Trident/.test(ua);
}
/**
 * Creates an array containing all the numbers between "start" and "end" (inclusive), optionally with a "step" interval.
 * @param start Start of the range
 * @param end End of the range
 * @param step Interval between elements
 * @returns Array of numbers
 */
function range(start, end, step) {
    if (step === void 0) { step = 1; }
    var ret = Array(Math.ceil((end - start + 1) / step));
    for (var i = 0; i < ret.length; i++) {
        ret[i] = start + i * step;
    }
    return ret;
}
/**
 * Wrapper function to prevent repeated calls to GTM callback functions
 * @param callback Callback function to wrap
 * @returns Wrapped callback function
 */
function GTMCallback(callback) {
    return function (containerId) {
        /**
         * If trackers are blocked containerId === undefined
         * Google Tag Manager container id starts with "GTM-"
         */
        if ((!containerId || containerId.startsWith("GTM-")) && typeof callback === "function") {
            callback();
        }
    };
}
function setupVideoEmbeds(selector) {
    if (selector === void 0) { selector = '.video-container'; }
    var videos = (typeof selector === 'string' ? $(selector).toArray() : selector instanceof Array ? selector : [selector]);
    videos.forEach(function (video) {
        if (!video.classList.contains('loaded')) {
            var src_1 = video.dataset.src;
            var script_1 = video.dataset.script;
            if (src_1.length > 0) {
                if (script_1.length > 0) {
                    var init_1 = function () {
                        $.getScript(script_1);
                        $(video).find('iframe')[0].src = src_1;
                        video.classList.add('loaded');
                        $(video).off('click', init_1);
                    };
                    $(video).on('click', init_1);
                }
                else {
                    var init_2 = function () {
                        $(video).find('iframe')[0].src = src_1;
                        video.classList.add('loaded');
                        $(video).off('click', init_2);
                    };
                    $(video).on('click', init_2);
                }
            }
        }
    });
}
