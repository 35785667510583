function menuComponent() {
    $(document)
        .on('toggle:was-opened', function (event, selector) {
            if (selector === '#menu') {
                responsive({
                    tablet: function() {
                        $('.bottom-mobile').addClass('hide');
                        $('.show-on-mobile-menu-open').addClass('show');
                        $('.show-on-mobile-menu-closed').hide();
                        $('header').addClass('bg-light-grey');
                    }
                });
            }
        })
        .on('toggle:was-closed', function (event, selector) {
            if (selector === '#menu') {
                responsive({
                    tablet: function() {
                        $('.bottom-mobile').removeClass('hide');
                        $('.show-on-mobile-menu-open').removeClass('show');
                        $('.show-on-mobile-menu-closed').show();
                        $('header').removeClass('bg-light-grey');
                    }
                });
            }
        });

        $("#logout-mobile").on('click',function(event){
            const form = $<HTMLFormElement>("#logout-form").get(0);
            if (form.requestSubmit) {
                form.requestSubmit();
            }
            else {
                form.submit();
            }
        })
        

    $(window).on('resize orientationchange', function() {
        const menu = $('#menu');
        responsive({
            tablet: function() {
                if (menu.is('.open')) {
                    blockPageScroll();
                    $('header').addClass('bg-light-grey');
                }
            },
            desktop: function() {
                restorePageScroll();
                $('header').removeClass('bg-light-grey');
            }
        });
    });
    
    $('.menu-item.has-submenu').not('.mobile.header').withData('level').children('a, span').on('click', function(event) {
        const thisItem = $(this).parent();
        const level = thisItem.data('level');

        responsive({
            tablet: function() {
                event.preventDefault();
                const otherItems = $('.menu-item.has-submenu').not('.mobile.header').withData('level', level).not(thisItem);
                otherItems.removeClass('open').trigger('menu:change');
                thisItem.toggleClass('open').trigger('menu:change');
            }
        });
    });

    $('.menu-item').not('.mobile.header').withData('level', '1').on('menu:change', function(event) {
        event.stopPropagation();
        const thisItem = $(this);
        const otherItems = $('.menu-item').not('.mobile.header').withData('level', '1').not(thisItem);

        if (thisItem.is('.open')) {
            thisItem.children('.submenu').slideDown();
            otherItems.slideUp();
            $('#mobile-menu-back').addClass('active');
        }
    });

    $('.menu-item.has-submenu').withData('level', '2').on('menu:change', function() {
        const thisItem = $(this);

        if (thisItem.is('.open')) {
            thisItem.children('.submenu').slideDown();
        }
        else {
            thisItem.children('.submenu').slideUp();
        }
    });

    $('#mobile-menu-back').on('click', function() {
        const openItem = $('.menu-item.open').not('.mobile.header').withData('level', '1');
        const otherItems = $('.menu-item').not('.mobile.header').not('.open').withData('level', '1');
        openItem.removeClass('open').find('.submenu').slideUp();
        otherItems.slideDown();
        $(this).removeClass('active');
    });
}

