/**
 * Initializes uppercase inputs within "scope". Use guide:
 * 
 * Terminology:
 *      uppercase input: input with the data-force-uppercase attribute, its values will be transformed to uppercase
 * 
 * Search input attributes:
 *      data-force-uppercase: Indicates that this element will be an uppercase input
 * 
 * @param scope Selector of the element within which activate uppercase input
 */
function initForceUpperCase(scope: JQuerySelector = document) {
    $(scope).find('input').withData('force-uppercase').not(initialized)
        .each(function() {
            $(this).data('force-uppercase-initialized', true);
        })
        .on('input', function() {
            const $this = $(this);
            const value = $this.value<string>().toUpperCase();
            $this.val(value);
        });

    function initialized() {
        return $(this).hasData('force-uppercase-initialized');
    }
}