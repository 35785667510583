function contactComponent() {
    if (exists('.g-recaptcha')) {
        loadCaptcha();
    }
    $('#sortpic').on('change', function (e) {
        var file_data = e.currentTarget.files[0];
        var input = $(this);
        var attachment = $('[name=attachment]');
        if (exists(attachment)) {
            attachment.remove();
        }
        if (file_data.size > 50 * Math.pow(2, 20) /* 50 MB */) {
            showMessageBox('danger', msg['ERR_FILE_TOO_LARGE'].replace(':size', '50 MB'));
            input.val(null);
        }
        else {
            var form_data = new FormData();
            form_data.append('file', file_data);
            form_data.append('type', 'buy2bee');
            $.ajax({
                method: 'POST',
                url: 'https://ftp.dev.zero11.net/storage/buy2bee/up.php',
                dataType: 'text',
                cache: false,
                contentType: false,
                processData: false,
                data: form_data,
                success: function (php_script_response) {
                    var data = JSON.parse(php_script_response);
                    if (data.status == 'OK') {
                        $("<input type=\"hidden\" name=\"attachment\" value=\"".concat(data.link, "\">")).insertAfter('#sortpic');
                    }
                    else {
                        input.val(null);
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    input.val(null);
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            });
        }
    });
    monitorInput('#contact-form', 'subject', function (input) {
        return $(input).val() != '';
    }, function () {
        var val = $('#contact-form').find('[name=subject]').val();
        $('#contact-form').find('.contact-form-options').hide();
        $('#' + val).show();
    }, function () {
        $('#contact-form').find('.contact-form-options').hide();
    });
}
function submitContactForm() {
    var $form = $('#contact-form');
    if ($form.valid()) {
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                }
                else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }
    else if (!$("#privacy").prop('checked')) {
        showMessageBox('danger', msg['MSG_PRIVACY_POLICY']);
    }
}
