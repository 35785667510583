/**
 * Initializes the <dialog> elements on the page.
 * If HTML <dialog> is not supported, a polyfill will be loaded from '/skins/polyfill'.
 * The necessary files ('dialog-polyfill.css' and 'dialog-polyfill.js') can be found in node_modules/dialog-polyfill/dist after running 'npm install'.
 * The 'polyfill' class will be added to polyfilled <dialog> elements.
 *
 * Terminology:
 *      dialog: <dialog> element
 *      opener: element(s) with the data-dialog-open attribute
 *
 * Dialog attributes:
 *      data-dialog-block-scroll-on-open: Page scroll will be blocked when the dialog is open
 *      data-dialog-close-on-backdrop-click: Dialog will be closed when its backdrop is clicked
 *      data-dialog-non-modal: Dialog will be opened in non-modal mode (no page scroll block and no backdrop)
 *      data-dialog-open-now: Dialog will be opened on page load
 *
 * Opener attributes:
 *      data-dialog-open="selector": Will open the dialog matching "selector" on click
 *
 * Dialog events:
 *      "open": triggered on the dialog when it opens
 *      "close": standard <dialog> close event https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/close_event
 *      "cancel": standard <dialog> cancel event https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/cancel_event
 *
 * @param scope Selector of the element within which activate the dialogs
 */
function initDialogs(scope) {
    if (scope === void 0) { scope = document; }
    //@ts-ignore until typescript DOM type definitions are updated
    if (typeof $('<dialog>')[0].showModal !== 'function') {
        var polyfill_1 = '/skins/polyfill/dialog-polyfill';
        $('head').append($("<link rel=\"stylesheet\" href=\"".concat(polyfill_1, ".css\" type=\"text/css\" media=\"screen,print\"/>")));
        loadScript("".concat(polyfill_1, ".js"))
            .then(function () {
            $('dialog').toArray().forEach(function (dialog) {
                dialogPolyfill.registerDialog(dialog);
                dialog.classList.add('polyfill');
            });
            runFunction();
        })
            .catch(function (reason) {
            console.error("Error loading ".concat(polyfill_1, ".js; ").concat(reason));
        });
    }
    else {
        runFunction();
    }
    function runFunction() {
        $(scope).find('dialog').not(initialized).each(function () {
            var dialog = $(this);
            var blockScrollOnOpen = dialog.hasData('dialog-block-scroll-on-open');
            var nonModal = dialog.hasData('dialog-non-modal');
            //@ts-ignore until typescript DOM type definitions are updated
            var showDialog = nonModal ? function () { return dialog[0].show(); } : function () { return dialog[0].showModal(); };
            var openers = $(scope).find('*').withData('dialog-open').filter(function () {
                var selector = $(this).data('dialog-open');
                return dialog.is(selector);
            });
            openers.on('click', function () {
                showDialog();
                dialog.trigger('open');
            });
            if (blockScrollOnOpen && !nonModal) {
                dialog.on('open', blockPageScroll);
                dialog.on('close', function () {
                    var otherOpenDialogs = $('dialog[open]').not(dialog);
                    if (!exists(otherOpenDialogs)) {
                        restorePageScroll();
                    }
                });
            }
            if (dialog.hasData('dialog-close-on-backdrop-click')) {
                if (dialog.css('padding') != '0px') {
                    dialog.css('padding', '0px');
                }
                if (!dialog.css('border').includes('none')) {
                    dialog.css('border', 'none');
                }
                dialog.on('click', function (event) {
                    if ($(event.target).is(dialog)) {
                        //@ts-ignore until typescript DOM type definitions are updated
                        dialog[0].close();
                    }
                });
            }
            if (dialog.hasData('dialog-open-now')) {
                showDialog();
                dialog.trigger('open');
            }
            dialog.data('dialog-initialized', true);
        });
        function initialized() {
            return $(this).hasData('dialog-initialized');
        }
    }
}
