declare const dialogPolyfill: any;

/**
 * Initializes the <dialog> elements on the page.
 * If HTML <dialog> is not supported, a polyfill will be loaded from '/skins/polyfill'.
 * The necessary files ('dialog-polyfill.css' and 'dialog-polyfill.js') can be found in node_modules/dialog-polyfill/dist after running 'npm install'.
 * The 'polyfill' class will be added to polyfilled <dialog> elements. 
 * 
 * Terminology:
 *      dialog: <dialog> element
 *      opener: element(s) with the data-dialog-open attribute
 * 
 * Dialog attributes:
 *      data-dialog-block-scroll-on-open: Page scroll will be blocked when the dialog is open
 *      data-dialog-close-on-backdrop-click: Dialog will be closed when its backdrop is clicked
 *      data-dialog-non-modal: Dialog will be opened in non-modal mode (no page scroll block and no backdrop)
 *      data-dialog-open-now: Dialog will be opened on page load
 * 
 * Opener attributes:
 *      data-dialog-open="selector": Will open the dialog matching "selector" on click
 * 
 * Dialog events:
 *      "open": triggered on the dialog when it opens
 *      "close": standard <dialog> close event https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/close_event
 *      "cancel": standard <dialog> cancel event https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/cancel_event
 * 
 * @param scope Selector of the element within which activate the dialogs
 */
function initDialogs(scope: JQuerySelector = document) {
    //@ts-ignore until typescript DOM type definitions are updated
    if (typeof ($('<dialog>')[0] as HTMLDialogElement).showModal !== 'function') {
        const polyfill = '/skins/polyfill/dialog-polyfill';
        $('head').append($(
            `<link rel="stylesheet" href="${polyfill}.css" type="text/css" media="screen,print"/>`
        ));
        loadScript(`${polyfill}.js`)
            .then(() => {
                $('dialog').toArray().forEach(dialog => {
                    dialogPolyfill.registerDialog(dialog);
                    dialog.classList.add('polyfill');
                });
                runFunction();
            })
            .catch(reason => {
                console.error(`Error loading ${polyfill}.js; ${reason}`);
            })
    }
    else {
        runFunction();
    }

    function runFunction() {
        $(scope).find('dialog').not(initialized).each(function() {
            const dialog = $(this);
            const blockScrollOnOpen = dialog.hasData('dialog-block-scroll-on-open');
            const nonModal = dialog.hasData('dialog-non-modal');
            //@ts-ignore until typescript DOM type definitions are updated
            const showDialog = nonModal ? () => dialog[0].show() : () => dialog[0].showModal();
            const openers = $(scope).find('*').withData('dialog-open').filter(function() {
                const selector = $(this).data('dialog-open');
                return dialog.is(selector);
            });
    
            openers.on('click', () => {
                showDialog();
                dialog.trigger('open');
            });

            if (blockScrollOnOpen && !nonModal) {
                dialog.on('open', blockPageScroll);
                dialog.on('close', () => {
                    const otherOpenDialogs = $('dialog[open]').not(dialog);
                    if (!exists(otherOpenDialogs)) {
                        restorePageScroll();
                    }
                });
            }
            
            if (dialog.hasData('dialog-close-on-backdrop-click')) {
                if (dialog.css('padding') != '0px') {
                    dialog.css('padding', '0px');
                }
                if (!dialog.css('border').includes('none')) {
                    dialog.css('border', 'none');
                }
                dialog.on('click', function(event) {
                    if ($(event.target).is(dialog)) {
                        //@ts-ignore until typescript DOM type definitions are updated
                        dialog[0].close();
                    }
                });
            }

            if (dialog.hasData('dialog-open-now')) {
                showDialog();
                dialog.trigger('open');
            }
    
            dialog.data('dialog-initialized', true);
        });
    
        function initialized() {
            return $(this).hasData('dialog-initialized');
        }
    }
}