/**
 * Returns the Provinces associated to the provided "countryId".
 * @param {number} countryId Id of the provinces' country
 * @returns {Promise<Province[]>} Promise that resolves into an array of Province objects (empty array on fail)
 */
function loadProvinces(countryId) {
    return new Promise(function (resolve) {
        $.ajax({
            method: 'GET',
            url: "/restful/countries/provinces?countryId=".concat(countryId),
            success: function (data) {
                resolve(data.results);
            },
            error: function () {
                resolve([]);
            }
        });
    });
}
/**
 * Updates all province inputs according to the current value of the country input in the same form. If at least one province is found, the input will become a \<select\>, it will become/remain a \<input type="text"\> otherwise.
 */
function updateProvinces() {
    $('[name*="prov"]').not('[type=hidden]').each(function () {
        var $this = $(this);
        var country = $this.closest('form').find('[name*="country"]');
        if (country.length > 0) {
            loadProvinces(country.value()).then(function (provinces) {
                if (provinces.length > 0) {
                    var id = $this.attr('id');
                    var name_1 = $this.attr('name');
                    var type = $this.attr('type');
                    var value = $this.val();
                    var placeholder = $this.attr('placeholder');
                    var disabled = $this.is('[disabled], [readonly]') ? 'disabled' : '';
                    var readonly = $this.is('[readonly]') ? 'readonly' : '';
                    var required = $this.is('[required]') ? 'required' : '';
                    //@ts-ignore msg is guaranteed to exist
                    var options = ["<option class=\"hide\" disabled selected>".concat(msg['MSG_SELECT_PROVINCE'], "</option>")];
                    var hasSelectedValue = false;
                    for (var i = 0; i < provinces.length; i++) {
                        var province = provinces[i];
                        var selected = (province.code == value) ? 'selected' : '';
                        options.push("<option value=\"".concat(province.code, "\" ").concat(selected, ">").concat(province.name, " (").concat(province.code, ")</option>"));
                        hasSelectedValue = hasSelectedValue || (province.code == value);
                    }
                    if (!hasSelectedValue) {
                        options[0].replace('  ', ' selected');
                    }
                    if (readonly) {
                        $("<input type=\"hidden\" name=\"".concat(name_1, "\" value=\"").concat(value, "\" ").concat(required, "/>")).insertAfter($this);
                    }
                    $this.replaceWith("\n                        <select id=\"".concat(id, "\" name=\"").concat(name_1, "\" ").concat(disabled, " ").concat(readonly, " data-type=\"").concat(type, "\" ").concat(required, " data-placeholder=\"").concat(placeholder, "\" data-value=\"").concat(value, "\">\n                            ").concat(options.join('\n'), "\n                        </select>\n                    "));
                }
                else if ($this.is('select')) {
                    var id = $this.attr('id');
                    var name_2 = $this.attr('name');
                    var type = $this.data('type');
                    var value = $this.data('value');
                    var placeholder = $this.data('placeholder');
                    var disabled = $this.is('[disabled]:not([readonly])') ? 'disabled' : '';
                    var readonly = $this.is('[readonly]') ? 'readonly' : '';
                    var required = $this.is('[required]') ? 'required' : '';
                    if (readonly) {
                        $this.siblings("[name=".concat(name_2, "][type=hidden]")).remove();
                    }
                    $this.replaceWith("<input id=\"".concat(id, "\" type=\"").concat(type, "\" name=\"").concat(name_2, "\" ").concat(disabled, " ").concat(readonly, " ").concat(required, " value=\"").concat(value, "\" placeholder=\"").concat(placeholder, "\"/>"));
                }
            });
        }
    });
}
