/**
 * Extracts query parameters from the provided "url".
 * @param {string} url Url from which to obtain query parameters; current url by default
 * @returns {QueryParams} Query parameters object
 */
function getQueryParams(url) {
    if (url === void 0) { url = window.location.href; }
    var queryParams = {};
    var _a = url.split('?'), _ = _a[0], params = _a[1];
    if (params) {
        for (var _i = 0, _b = params.split('&'); _i < _b.length; _i++) {
            var param = _b[_i];
            var _c = param.split('='), key = _c[0], value = _c[1];
            if (queryParams[key] && value) {
                if (Array.isArray(queryParams[key])) {
                    queryParams[key].concat(value);
                }
                else {
                    queryParams[key] = [queryParams[key], value];
                }
            }
            else {
                queryParams[key] = value || '';
            }
        }
    }
    return queryParams;
}
/**
 * Extracts a single query parameter value from the current url.
 * @param {string} param Name of the parameter to get
 * @returns {string | string[] | ''} The value of the query parameter, or an empty string if the query parameter is not found
 */
function getQueryParam(param) {
    return getQueryParams()[param] || '';
}
/**
 * Sets a query parameter in the current url; if the parameter already exists it is overwritten.
 * @param {string} param Name of the query parameter to set
 * @param {string | string[]} value Value(s) of the parameter to set
 */
function setQueryParam(param, value) {
    var params = getQueryParams();
    params[param] = value;
    setQueryParams(params);
}
/**
 * Sets several query parameters in the current url; if the parameters already exists they will be overwritten.
 * @param {QueryParams} params Query parameters to set, defaults to an empty object
 */
function setQueryParams(params) {
    if (params === void 0) { params = {}; }
    if (Object.keys(params).length > 0) {
        window.location.search = queryParamsToString(params);
    }
    else {
        window.location.search = '';
    }
}
/**
 * Converts a QueryParams object to its string representation, ready to be assigned to window.location.search
 * @param {QueryParams} params Query parameters to convert, will use the query parameters of the current url by default
 * @returns String representation of the query parameters
 */
function queryParamsToString(params) {
    if (params === void 0) { params = getQueryParams(); }
    var query = [];
    for (var _i = 0, _a = Object.keys(params); _i < _a.length; _i++) {
        var param = _a[_i];
        if (params[param]) {
            if (Array.isArray(params[param])) {
                for (var _b = 0, _c = params[param]; _b < _c.length; _b++) {
                    var value = _c[_b];
                    query.push("".concat(param, "=").concat(value));
                }
            }
            else {
                query.push("".concat(param, "=").concat(params[param]));
            }
        }
    }
    return "?".concat(query.join('&'));
}
/**
 * Builds an url
 * @param {string} params.base Base of the url, defaults to window.location.origin + window.location.pathname if not provided
 * @param {QueryParams} params.queryParams Query parameters of the url, will use the ones of the current page by default
 * @param {string} params.hash Hash of the url, will use the one of the current page by default
 * @returns
 */
function buildUrl(params) {
    if (params === void 0) { params = {}; }
    var base = params.base || (window.location.origin + window.location.pathname);
    var queryParams = queryParamsToString(params.queryParams || getQueryParams());
    var hash = params.hash || window.location.hash;
    return base + queryParams + hash;
}
