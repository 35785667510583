/**
 * Initializes search inputs within "scope". Use guide:
 * 
 * Terminology:
 *      search input: input with the data-search attribute
 *      search targets: elements indicated by the data-search attribute parameter, will be shown/hidden according to the search query
 *      search query: value of the search input
 * 
 * Search input attributes:
 *      data-search="selector": Indicates that this element will be a search inputand that the search targets will be the elements matching "selector"
 *      data-search-case-insensitive: makes the search case insensitive
 * 
 * Search target classes:
 *      .search-result: when the target matches the search query
 *      .search-failed: when the target does not match the search query
 * 
 * Search target events:
 *      "search:query": tests the search target's text against the search query
 *      "search:reset": reset search results (will show the element)
 * 
 * @param scope Selector of the element within which activate search inputs
 */
function initSearch(scope: JQuerySelector = document) {
    $(scope).find('input').withData('search').not(initialized).each(function() {
        const searchInput = $(this);
        const searchTargetsSelector = searchInput.data('search');
        const ignoreCase = searchInput.hasData('search-case-insensitive');

        searchInput.data('search-initialized', true);

        if (exists(searchTargetsSelector)) {
            const searchTargets = $(searchTargetsSelector);

            searchInput.on('input', function() {
                const value = searchInput.val();

                if (value) {
                    searchTargets.trigger('search:query', value);
                }
                else {
                    searchTargets.trigger('search:reset');
                }
            });

            searchInput.siblings('.search-cancel').on('click', function () {
                searchInput.val('').trigger('input');
            });

            searchTargets
                .on('search:query', function(event, search) {
                    const target = $(this);
                    let text = target.text();

                    if (ignoreCase) {
                        text = text.toLowerCase();
                        search = search.toLowerCase();
                    }

                    if (text.includes(search)) {
                        target
                            .removeClass('search-failed')
                            .addClass('search-result')
                            .show();
                    }
                    else {
                        target
                            .removeClass('search-result')
                            .addClass('search-failed')
                            .hide();
                    }
                })
                .on('search:reset', function() {
                    $(this)
                        .removeClass(['search-result', 'search-failed'])
                        .show();
                });

            searchInput.trigger('input');
        }
    });

    function initialized() {
        return $(this).hasData('search-initialized');
    }
}